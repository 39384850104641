<template>
  <v-row
    style="background-color: #003b77 !important"
    align="center"
    justify="center"
    class="no-gutters"
  >
    <v-col cols="12" class="text-center mt-10">
      <p class="white--text text-h4 text-sm-h2 font-weight-bold px-10">RAZONES PARA ELEGIR</p>
      <p class="primary--text text-h5 font-weight-bold">
        DIT TRANSPORTE Y ALMACENAJE
      </p>
    </v-col>
    <v-col cols="10" sm="8" md="11" lg="10">
      <v-card class="mt-5 mb-15" color="#FFFFFF" dark :class="radius">
        <v-row class="hidden-sm-and-down no-gutters" align="center">
          <v-col>
            <p
              class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
            >
              TRANSPORTE EN LOS<br /><span
                class="dit-text-primary text-h2 font-weight-bold"
              >
                9</span
              ><br />
              DEPARTAMENTOS
            </p>
          </v-col>
          <v-divider
            class="hidden-sm-and-down mt-5 mb-5"
            vertical
            style="background-color: #009ee2"
          ></v-divider>
          <v-col>
            <p
              class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
            >
              ALMACENES FIJOS EN<br /><span
                class="dit-text-primary text-h2 font-weight-bold"
              >
                3</span
              ><br />
              CIUDADES PRINCIPALES
            </p>
          </v-col>
          <v-divider
            class="hidden-sm-and-down mt-5 mb-5"
            vertical
            style="background-color: #009ee2"
          ></v-divider>
          <v-col>
            <p
              class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
            >
              CONTAMOS CON:<br /><span
                class="dit-text-primary text-body-1 font-weight-bold"
              >
                MONTACARGAS<br />
                CAMIONETAS<br />
                GRÚAS<br />
                CAMIONES DE ALTO TONELAJE</span
              >
            </p>
          </v-col>
          <v-divider
            class="hidden-sm-and-down mt-5 mb-5"
            vertical
            style="background-color: #009ee2"
          ></v-divider>
          <v-col>
            <p
              class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
            >
              ATENCIÓN DISPONIBLE<br /><span
                class="dit-text-primary text-h2 font-weight-bold"
              >
                24/7</span
              ><br />
              EN TODA BOLIVIA
            </p>
          </v-col>
          <v-divider
            class="hidden-sm-and-down mt-5 mb-5"
            vertical
            style="background-color: #009ee2"
          ></v-divider>
          <v-col>
            <p
              class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
            >
              SERVICIO CONTÍNUO<br /><span
                class="dit-text-primary text-h2 font-weight-bold"
              >
                365</span
              ><br />
              DÍAS DEL AÑO
            </p>
          </v-col>
        </v-row>
        <v-row class="hidden-md-and-up">
          <v-col cols="12">
            <p
              class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
            >
              TRANSPORTE EN LOS<br /><span
                class="dit-text-primary text-h2 font-weight-bold"
              >
                9</span
              ><br />
              DEPARTAMENTOS
            </p>
            <v-divider
              style="background-color: #009ee2"
              class="ml-5 mr-5 hidden-md-and-up"
            ></v-divider>
            <v-col cols="12">
              <p
                class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
              >
                ALMACENES FIJOS EN<br /><span
                  class="dit-text-primary text-h2 font-weight-bold"
                >
                  3</span
                ><br />
                CIUDADES PRINCIPALES
              </p>
              <v-divider
                style="background-color: #009ee2"
                class="ml-5 mr-5 hidden-md-and-up"
              ></v-divider>
            </v-col>
            <v-col cols="12">
              <p
                class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
              >
                CONTAMOS CON:<br /><span
                  class="dit-text-primary text-body-1 font-weight-bold"
                >
                  MONTACARGAS<br />
                  CAMIONETAS<br />
                  GRÚAS<br />
                  CAMIONES DE ALTO TONELAJE</span
                >
              </p>
              <v-divider
                style="background-color: #009ee2"
                class="ml-5 mr-5 hidden-md-and-up"
              ></v-divider>
            </v-col>
            <v-col cols="12">
              <p
                class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
              >
                ATENCIÓN DISPONIBLE<br /><span
                  class="dit-text-primary text-h2 font-weight-bold"
                >
                  24/7</span
                ><br />
                EN TODA BOLIVIA
              </p>
              <v-divider
                style="background-color: #009ee2"
                class="ml-5 mr-5 hidden-md-and-up"
              ></v-divider>
            </v-col>
            <v-col cols="12">
              <p
                class="dit-text-accent text-center font-weight-bold d-block pt-5 pl-5 pr-5 text-body-1"
              >
                SERVICIO CONTÍNUO<br /><span
                  class="dit-text-primary text-h2 font-weight-bold"
                >
                  365</span
                ><br />
                DÍAS DEL AÑO
              </p>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      model: 4,
      rounded: ["0", "sm", "md", "lg", "xl", "pill", "circle"],
      is_vertical: this.$vuetify.breakpoint.xs
        ? this.$vuetify.breakpoint.sm
          ? false
          : true
        : true,
      is_no_vertical: this.$vuetify.breakpoint.xs ? true : false,
    };
  },

  computed: {
    radius() {
      let rounded = "rounded";
      const value = this.rounded[this.model];

      if (value !== "md") {
        rounded += `-${value}`;
      }

      return rounded;
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 769) and (max-width: 4000px) {
  .flex .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>