import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_profile: [],
    is_loggedin: false,
    shop_cart: [],
    login_interface: false,
    register_interface: false,
    contactItems: [
      {
        icon: "mdi-email",
        title: "info@ditgroup.com.bo",
        color: "#009EE2",
        href: "mailto:info@ditgroup.com.bo",
      },
      {
        icon: "mdi-phone-in-talk",
        title: "2 2792228",
        color: "#1B4864",
        href: "tel:22792228",
      },
      {
        icon: "mdi-whatsapp",
        title: "(591) 67006061",
        color: "#25D366",
        href: "https://wa.me/59167006061",
      },
      {
        icon: "mdi-facebook",
        title: "DIT GROUP",
        color: "#4267B2",
        href: "https://www.facebook.com/ditgroupsrl",
      },
      {
        icon: "mdi-instagram",
        title: "dit.group",
        color: "#F56040",
        href: "https://www.instagram.com/dit.group/",
      },
    ],
  },
  mutations: {
    changeStateLoginInterface(state) {
      // mutate state
      state.login_interface = !state.login_interface
    },
    changeStateRegisterInterface(state) {
      // mutate state
      state.register_interface = !state.register_interface
    },
    login(state, user_profile) {
      localStorage.setItem('user_profile', JSON.stringify(user_profile));
      localStorage.setItem('is_loggedin', true);
      state.user_profile = user_profile;
      state.is_loggedin = true;
      state.login_interface = false;
      state.register_interface = false;
    },
    logout(state) {
      localStorage.removeItem("user_profile");
      localStorage.removeItem("is_loggedin");
      localStorage.removeItem("shop_cart");
      state.user_profile = [];
      state.is_loggedin = false;
      state.shop_cart = [];
    },
    set_shop_cart(state) {
      if (localStorage.getItem("shop_cart")) {
        state.shop_cart = JSON.parse(localStorage.getItem("shop_cart"));
      }
    },
    add_item_cart(state, item) {
      let new_item = item.item;
      new_item.quantity = item.quantity;
      new_item.totalprice = new_item.price * new_item.quantity;
      console.log(new_item);
      if (localStorage.getItem("shop_cart")) {
        state.shop_cart = JSON.parse(localStorage.getItem("shop_cart"));
      }
      state.shop_cart.push(new_item);
      localStorage.setItem("shop_cart", JSON.stringify(state.shop_cart));
    },
    clear_shop_cart(state) {
      localStorage.removeItem("shop_cart");
      state.shop_cart = [];
    }
  },
  actions: {

  },
  modules: {
  }
})
