var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pb-15 no-gutters",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center py-10 px-5",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h5 text-sm-h4 text-lg-h3 font-weight-bold dit-text-primary"},[_vm._v(" VENTAJAS DE "),_c('span',{staticClass:"dit-text-accent"},[_vm._v("DIT OUTSOURCING")])])]),_c('v-col',{attrs:{"cols":"10","md":"10","lg":"9","xl":"6"}},[_c('v-row',_vm._l((_vm.content),function(item){return _c('v-col',{key:item.title,attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"rounded-xl",style:(item.hover ? _vm.style : _vm.style_hover),attrs:{"height":_vm.$vuetify.breakpoint.xs
              ? '140px'
              : _vm.$vuetify.breakpoint.sm
              ? '130px'
              : _vm.$vuetify.breakpoint.md
              ? '120px'
              : _vm.$vuetify.breakpoint.lg
              ? '100px'
              : '100px'},on:{"mouseover":function($event){item.hover = true},"mouseleave":function($event){item.hover = false}}},[_c('v-row',{staticClass:"dit-text-accent font-weight-bold text-h5 text-center no-gutters mx-10",staticStyle:{"height":"100%"},attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(item.title))])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }