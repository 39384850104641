<template>
  <v-row
    class="dit-hero no-gutters"
    :style="
      $vuetify.breakpoint.xs
        ? 'height:450px'
        : $vuetify.breakpoint.sm
        ? 'height:455px'
        : $vuetify.breakpoint.md
        ? 'height:475px'
        : $vuetify.breakpoint.lg
        ? 'height:670px'
        : 'height:890px'
    "
    justify="center"
  >
    <v-col cols="12" align-self="start"></v-col>
    <v-col
      cols="10"
      sm="8"
      md="8"
      class="text-center dit-letter"
      align-self="center"
    >
      <p
        class="text-h4 text-sm-h3 text-md-h2 dit-text-shadow font-weight-bold dit-letter mb-0"
        style="color: #009ee2; letter-spacing: 0.01em !important"
      >
        Contáctanos
      </p>
      <p
        class="text-h4 text-sm-h3 text-md-h2 dit-text-shadow font-weight-bold white--text dit-letter"
        style="letter-spacing: 0.01em !important"
      >
        para desarrollar tu proyecto
      </p>
      <v-row justify="center">
        <v-col cols="10" sm="8" md="8" class="pt-5">
          <v-btn
            large
            color="#009EE2"
            class="ma-2 dit-btn white--text rounded-pill"
            to="/contacto"
            >Contactar</v-btn
          >
          <v-btn
            large
            outlined
            color="white"
            class="ma-2 dit-btn-outlined rounded-pill"
            v-on:click="scrollfade('scrolled')"
            >¿Qué hacemos?</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col align-self="end" cols="12" class="text-center py-5"
      ><a v-on:click="scrollfade('scrolled')"
        ><img
          :src="require('@/assets/images/mouse_down.svg')"
          height="55px" /></a
    ></v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
</style>