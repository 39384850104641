<template>
  <div>
    <v-row justify="center" class="pb-10 pt-5 no-gutters">
      <v-col cols="12">
        <v-row justify="space-between" v-if="is_loggedin" class="no-gutters">
          <v-col cols="auto" sm="6">
            <p
              class="font-weight-semibold primary--text text-h6 pl-sm-15 pl-5"
              style="line-height: 1.1rem"
            >
              {{ user_profile.name }}<br />
              <span class="text-body-2 black--text">{{
                user_profile.company
              }}</span>
            </p>
          </v-col>
          <v-col cols="auto" sm="6" class="text-right pr-5">
            <v-badge
              bordered
              color="accent"
              :content="shop_cart.length"
              overlap
              class="mx-2"
              v-if="shop_cart.length > 0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    to="/ventas/carrito"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-shopping</v-icon>
                  </v-btn>
                </template>
                <span>Mi Carrito</span>
              </v-tooltip>
            </v-badge>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="grey lighten-1">mdi-shopping</v-icon>
                </v-btn>
              </template>
              <span>Mi Carrito</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                  to="/ventas/mis_ordenes"
                >
                  <v-icon>mdi-receipt</v-icon>
                </v-btn>
              </template>
              <span>Mis Cotizaciones</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.commit('logout')"
                >
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Cerrar sesión</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-row class="no-gutters" justify="end">
              <v-col cols="6" sm="auto" class="px-sm-3 text-center">
                <v-btn
                  class="sales_hover"
                  @click="$store.commit('changeStateLoginInterface')"
                  dense
                >
                  Inicie sesión
                </v-btn>
              </v-col>
              <v-col cols="6" sm="auto" class="px-sm-3 text-center">
                <v-btn
                  dense
                  color="primary"
                  class="sales_hover"
                  @click="$store.commit('changeStateRegisterInterface')"
                >
                  Registrarse
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="iscategory" class="ma-10">
          <v-col
            cols="12"
            sm="4"
            lg="2"
            md="4"
            v-for="item in categories"
            :key="item.title"
          >
            <v-card
              style="border: 1px solid #009ee2 !important; border-radius: 50px"
              v-on:click="setlist(item.action)"
              @mouseover="item.isHover = true"
              @mouseleave="item.isHover = false"
            >
              <v-img :src="item.img_path" :lazy-src="item.img_path"></v-img>
              <p
                class="
                  text-center
                  d-block
                  font-weight-bold
                  mt-2
                  text-subtitle-1
                "
                :class="item.isHover ? 'dit-none' : 'dit-text-primary'"
              >
                {{ item.title }}
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else-if="isListofProducts" justify="center" class="ma-10">
          <v-col cols="12">
            <v-btn plain x-large v-on:click="iscategory = true">
              <v-icon left>mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
          <template v-if="list.length > 0">
            <v-col
              cols="10"
              sm="5"
              md="4"
              lg="3"
              v-for="item in list"
              :key="item.id"
              class="text-center dit-product"
              v-on:click="setProduct(item)"
            >
              <v-card
                style="
                  border: 1px solid #009ee2 !important;
                  border-radius: 50px;
                "
              >
                <v-img
                  :src="item.productImagePath"
                  :lazy-src="item.productImagePath"
                  aspect-ratio="1"
                ></v-img>
              </v-card>
              <p class="text-center font-weight-bold mt-5 text-h6">
                {{ item.model }}
              </p>
              <v-btn
                outlined
                large
                color="#009EE2"
                class="ma-2 dit-btn-store rounded-pill"
                v-if="user_profile.length > 0"
                >Añadir al carrito</v-btn
              >
            </v-col>
          </template>

          <v-col v-else class="text-center text-h6 py-15">
            Muy pronto añadiremos mas productos.
          </v-col>
        </v-row>
        <v-row v-else class="ma-10">
          <v-col cols="12">
            <v-btn plain x-large v-on:click="setlist(product.category)">
              <v-icon left>mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
          <v-col>
            <v-row align="center" justify="center">
              <v-col cols="7" sm="4" md="3">
                <v-card
                  style="
                    border: 3px solid #009ee2 !important;
                    border-radius: 50px;
                  "
                  class="pa-2"
                >
                  <v-img
                    :src="product.productImagePath"
                    :lazy-src="product.productImagePath"
                    class="pa-5"
                    aspect-ratio="1"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="10" sm="6" md="7">
                <v-row justify="center">
                  <v-col cols="10">
                    <p>{{ product.description }}</p>
                  </v-col>
                  <v-col cols="10">
                    <label for="quantity" class="font-weight-bold"
                      >Cantidad:</label
                    >
                    <v-text-field
                      hide-details
                      single-line
                      id="quantity"
                      value="0"
                      v-model="quantity"
                      type="number"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="increment_quantity"
                      @click:prepend="decrement_quantity"
                      prepend-icon="mdi-minus"
                      label="Cantidad"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <v-btn
                      large
                      color="#009EE2"
                      class="ma-2 dit-btn white--text rounded-pill"
                      @click="addtocart(product)"
                      >Añadir a cotización</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Login v-bind:is_active="login_interface" />
    <Register v-bind:is_active="register_interface" />
    <v-snackbar v-model="snackbar" timeout="3000" color="primary">
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase";
import Login from "./login.vue";
import Register from "./register.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      products: [],
      packages: [],
      saleslist: [],
      list: [],
      product: {},
      quantity: 1,
      isLoaded: false,
      iscategory: true,
      isListofProducts: false,
      snackbar: false,
      snackbar_text: "",
      categories: [
        {
          title: "Todos los productos",
          img_path: require("@/assets/images/ventas/jpg/todos.jpg"),
          action: "TODO",
          isHover: false,
        },
        {
          title: "Insumos",
          img_path: require("@/assets/images/ventas/jpg/insumos.jpg"),
          action: "INSUMOS",
          isHover: false,
        },
        {
          title: "Ferretería",
          img_path: require("@/assets/images/ventas/jpg/ferreteria.jpg"),
          action: "FERRETERÍA",
          isHover: false,
        },
        {
          title: "Postes y cámaras",
          img_path: require("@/assets/images/ventas/jpg/postesycamaras.jpg"),
          action: "POSTES Y CÁMARAS",
          isHover: false,
        },
        {
          title: "Seguridad Industrial",
          img_path: require("@/assets/images/ventas/jpg/seguridad_industrial.jpg"),
          action: "SEGURIDAD INDUSTRIAL",
          isHover: false,
        },
        {
          title: "Promociones",
          img_path: require("@/assets/images/ventas/jpg/promociones.jpg"),
          action: "PROMOCIONES",
          isHover: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      login_interface: (state) => state.login_interface,
      register_interface: (state) => state.register_interface,
      user_profile: (state) => state.user_profile,
      is_loggedin: (state) => state.is_loggedin,
      shop_cart: (state) => state.shop_cart,
    }),
  },
  components: {
    Login,
    Register,
  },
  beforeMount() {
    this.getsaleslist();
  },
  mounted() {
    if (localStorage.getItem("user_profile")) {
      this.$store.commit(
        "login",
        JSON.parse(localStorage.getItem("user_profile"))
      );
    }
    if (localStorage.getItem("shop_cart")) {
      this.$store.commit('set_shop_cart');
    }
  },
  methods: {
    getsaleslist: function () {
      const self = this;
      firebase
        .firestore()
        .collection("products")
        .get()
        .then(function (querySnapshot) {
          let products = [];
          querySnapshot.forEach((doc) => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
          });
          self.products = products;
        })
        .then(() => {
          firebase
            .firestore()
            .collection("packages")
            .get()
            .then(function (querySnapshot) {
              let packages = [];
              querySnapshot.forEach((doc) => {
                let packs = doc.data();
                packs.id = doc.id;
                packages.push(packs);
              });
              self.packages = packages;

              self.products.forEach((product) => {
                let saleitem = {};
                if (product.category !== "Ninguno") {
                  saleitem = product;
                  self.saleslist.push(saleitem);
                }
              });
              self.packages.forEach((pack) => {
                let saleitem = pack;
                saleitem.category = "PROMOCIONES";
                self.saleslist.push(saleitem);
              });
              self.isLoaded = true;
              self.list = self.saleslist;
            });
        });
    },
    setlist: function (Category) {
      this.quantity = 0;
      this.iscategory = false;
      this.isListofProducts = true;
      this.product = {};
      if (Category === "TODO") {
        this.list = this.saleslist;
      } else {
        let newest = [];
        this.saleslist.forEach((item) => {
          if (item.category === Category) {
            newest.push(item);
          }
        });
        this.list = newest;
      }
    },
    setProduct: function (Product) {
      this.quantity = 1;
      this.iscategory = false;
      this.isListofProducts = false;
      this.product = Product;
    },
    addtocart: function (item) {
      let quantity = this.quantity;
      this.quantity = 1;
      this.$store.commit("add_item_cart", { item, quantity });
      this.snackbar_text = "Producto Agregado";
      this.snackbar = true;
      /*item.totalprice = item.price * item.quantity;
      this.shop_cart.push(item);
      localStorage.setItem("shop_cart", JSON.stringify(this.shop_cart));
      this.overlay = false;*/
    },
    onImageLoad: function () {
      this.imageload = true;
    },
    decrement_quantity() {
      this.quantity--;
    },
    increment_quantity() {
      this.quantity++;
    },
  },
};
</script>

<style lang="scss">
.dit-product {
  p {
    color: #009ee2;
  }
  &:hover {
    p {
      color: black;
    }
  }
}
.sales_hover {
  &:hover {
    color: #009ee2;
  }
}
</style>