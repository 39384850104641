<template>
  <v-dialog v-model="is_active" max-width="500px" persistent>
    <v-card>
      <v-toolbar color="primary white--text" dark>
        <v-toolbar-title class="font-weight-bold">Login</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
              <v-btn
                dark
                icon=""
                @click="$store.commit('changeStateLoginInterface')"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
      </v-toolbar>
      <v-form ref="_form" v-model="valid" lazy-validation>
        <v-row no-gutters class="mx-5 pt-10">
          <v-col cols="12">
            <v-text-field
              outlined
              label="Email"
              required
              :rules="emailRules"
              v-model="user_profile.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nit"
              type="password"
              required
              :rules="inputRules"
              v-model="user_profile.nit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="login_alert.status">
            <v-alert :type="login_alert.type">
              {{ login_alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary darken-1"
              outlined
              @click="login"
              block
              class="mb-15"
              >Login</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
export default {
  props: ["is_active"],
  data() {
    return {
      isopen: true,
      user_profile:[],
      valid: true,
      login_alert: { status: false },
      emailRules: [
        (v) => !!v || "Campo Requerido",
        (v) => /.+@.+/.test(v) || "Introduzca un email valido",
      ],
      inputRules: [
        (v) => !!v || "Campo Requerido",
        (v) =>
          (v && v.length <= 50 && v.length) ||
          "Name must be less than 10 characters",
      ],
    };
  },
  methods: {
    login: function () {
      const self = this;
      if (this.$refs._form.validate()) {
        firebase
          .firestore()
          .collection("clients")
          .where("email", "==", this.user_profile.email)
          .where("nit", "==", this.user_profile.nit)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              self.login_alert.type = "error";
              self.login_alert.message = "Introduzca datos correctos";
              self.login_alert.status = true;
            } else {
              self.login_dialog = false;
              self.login_alert = { status: false };
              querySnapshot.forEach((doc) => {
                let profile = doc.data();
                profile.id = doc.id;
                self.user_profile = profile;
              });
              self.$store.commit('login',self.user_profile)
            }
          });
      }
    },
  },
};
</script>

<style>
</style>