<template>
  <v-row
    class="dit-hero-mantenimiento no-gutters"
    :style="
      $vuetify.breakpoint.xs
        ? 'height:1550px'
        : $vuetify.breakpoint.sm
        ? 'height:1555px'
        : $vuetify.breakpoint.md
        ? 'height:775px'
        : $vuetify.breakpoint.lg
        ? 'height:770px'
        : $vuetify.breakpoint.xl
        ? 'height:870px'
        : 'height:890px'
    "
    align="center"
    justify="center"
  >
    <v-col cols="10" sm="10" md="5">
      <v-row class="no-gutters" justify="center">
        <v-col cols="8">
          <v-img :src="image"></v-img>
        </v-col>
        <v-col cols="8" class="white--text text-center mb-9">
          {{ title }}
        </v-col>
        <v-col cols="10" class="text-center">
          <v-btn
            large
            color="#009EE2"
            class="ma-2 dit-btn white--text rounded-pill"
            to="/contacto"
            >Contactar</v-btn
          ><v-btn
            outlined
            large
            color="#009EE2"
            class="ma-2 dit-btn-outlined white--text rounded-pill"
            v-on:click="scrollfade('scrolled')"
            >¿Qué ofrecemos?</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="10" sm="10" md="5"> <Contact :info="formitems" /> </v-col>
    <v-col align-self="end" cols="12" class="text-center py-5"
      ><a v-on:click="scrollfade('scrolled')"
        ><img
          :src="require('@/assets/images/mouse_down.svg')"
          height="55px" /></a
    ></v-col>
  </v-row>
</template>

<script>
import Contact from "../../forms/contact.vue";
export default {
  data() {
    return {
      title:
        "El área de mantenimiento está dedicada a nuestros clientes, cuenta con personal y logística para atender los eventos que se susciten en el día a día.",
      image: require("@/assets/images/mantenimiento/svg/logo.svg"),
      formitems: {
        title: "¡Déjanos tus datos para enviarte más información!",
        divisions: [
          {
            title: "DitMantenimiento",
            title_lowercase: "ditmantenimiento",
            areas: [
              "Redes FTTH",
              "Obras civiles",
              "Red de transporte",
              "Transmisión",
            ],
          },
        ],
      },
    };
  },
  components: {
    Contact,
  },
  methods: {
    validate: function () {
      console.log(this.form);
    },
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
</style>