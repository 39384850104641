<template>
  <div>
    <Hero />
    <OurServices />
    <Banner_hero :info="content" />
    <Reasons />
    <Banner_hero :info="ourprovider" />
    <Clients />
  </div>
</template>

<script>
import Hero from "../../components/divisions/transporte/hero.vue";
import OurServices from "../../components/divisions/transporte/advantage.vue";
import Clients from "../../components/home/clients.vue";
import Banner_hero from "../../components/banner_hero.vue";
import Reasons from "../../components/divisions/transporte/reasons.vue";
export default {
  data() {
    return {
      content: {
        title: "¿Qué servicio necesitas?",
        content:
          "Te asesoramos en la solución de transporte o almacenaje que estás buscando",
        img_path: require("@/assets/images/transporte/jpg/consulta.jpg"),
        button_text: "Tengo una consulta",
        button_to: "/contacto",
      },
      ourprovider: {
        title: "¿Te interesa ser proveedor?",
        content:
          "Registra a tu empresa para que un ejecutivo se ponga en contacto.",
        button_text: "Registrarse",
        button_to: "/nuestro_proveedor",
      },
    };
  },
  components: { Hero, OurServices, Clients, Banner_hero, Reasons },
};
</script>

<style>
</style>