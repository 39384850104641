<template>
  <div>
    <Hero />
    <Ourservices />
    <Allies />
    <Proposal />
    <Clients />
  </div>
</template>

<script>
import Allies from "../components/home/allies.vue";
import Clients from "../components/home/clients.vue";
import Hero from "../components/home/hero.vue";
import Ourservices from "../components/home/ourservices.vue";
import Proposal from "../components/home/proposal.vue";
export default {
  name: "Home",
  components: { Ourservices, Hero, Allies, Proposal, Clients },
};
</script>
<style lang="scss">

</style>
