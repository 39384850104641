<template>
  <v-row
    class="no-gutters"
    justify="center"
    style="background-color: #e5e5e5"
    id="scrolled"
  >
    <v-col cols="10">
      <p
        class="
          text-h5 text-sm-h4 text-md-h4 text-lg-h3
          dit-text-primary
          font-weight-bold
          text-center
          mt-10
          mb-10
        "
      >
        ¿Qué ofrecemos?
      </p></v-col
    >
    <v-col cols="10" sm="10" md="6" class="mb-15">
      <v-row justify="center">
        <v-col
          cols="10"
          sm="5"
          md="5"
          xl="5"
          v-for="item in services"
          :key="item.path"
        >
          <v-card
            :style="item.style"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            :color="item.hover ? item.color : 'transparent'"
            :height="
              $vuetify.breakpoint.xs
                ? '240px'
                : $vuetify.breakpoint.sm
                ? '240px'
                : $vuetify.breakpoint.md
                ? '220px'
                : $vuetify.breakpoint.lg
                ? '280px'
                : '380px'
            "
            v-on:click="scrollfade(item.title)"
          >
            <v-row
              align="center"
              justify="center"
              class="no-gutters"
              style="height: 100%"
            >
              <v-col cols="8">
                <v-img
                  :src="item.hover ? item.hover_path : item.path"
                  class="ml-3 mr-3 mt-3"
                />
              </v-col>
              <v-col cols="10">
                <p
                  class="
                    text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 text-center
                    font-weight-bold
                  "
                  :style="item.hover ? 'color:white' : 'color:#635F63'"
                >
                  {{ item.title }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="background-color: #ffffff">
      <v-row justify="center" class="mt-15 mb-15 no-gutters">
        <v-col
          cols="8"
          v-for="item in services"
          :key="item.path"
          :id="item.title"
        >
          <v-card :style="item.style">
            <v-row align="start" justify="center" class="mt-5 mb-5">
              <v-col cols="10" sm="8" md="4" lg="3" xl="3">
                <v-img :src="item.path" class="ml-10 mr-10" />
              </v-col>
              <v-col cols="8">
                <p
                  class="
                    text-sm-h6 text-md-h5 text-lg-h4 text-left
                    font-weight-bold
                  "
                  style="color: #009ee2"
                >
                  {{ item.title }}
                </p>
                <p>
                  {{ item.content }}
                </p>
                <ul>
                  <li v-for="l in item.list" :key="l">{{ l }}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card>
          <v-col cols="12" style="text-align: center">
            <v-btn
              large
              color="#009EE2"
              class="mt-10 mb-5 dit-btn-mantenimiento white--text rounded-pill"
              to="/contacto"
            >
              Contactar
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Redes FTTH",
          path: require("@/assets/images/mantenimiento/svg/redes.svg"),
          hover: false,
          hover_path: require("@/assets/images/mantenimiento/svg/redes_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#635F63",
          content:
            "Los servicios ofrecidos en mantenimiento de redes FTTH son:",
          list: [
            "Troubleshooting por punto.",
            "Mediciones y reparación de pérdidas de potencia.",
            "Reactivación de red FTTH por corte.",
            "Migración en red activa en horario ventana.",
            "Reparación de red de plnata externa.",
            "Adecuaciones civiles.",
          ],
        },
        {
          title: "Obras civiles",
          path: require("@/assets/images/mantenimiento/svg/obras.svg"),
          hover: false,
          hover_path: require("@/assets/images/mantenimiento/svg/obras_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#635F63",
          content: "",
          list: [
            "Reparación de mimetizados.",
            "Impermeabilizado de estaciones.",
            "Demoliciones de estaciones u otras infraestructuras.",
            "Mantenimiento preventivo y reparaciones de casetas o shelter.",
            "Limpieza y desmonte de estaciones.",
            "Limpieza de caminos y desbroce.",
          ],
        },

        {
          title: "Red de transporte",
          path: require("@/assets/images/mantenimiento/svg/transporte.svg"),
          hover: false,
          hover_path: require("@/assets/images/mantenimiento/svg/transporte_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#635F63",
          content: "Los servicios ofrecidos en mantenimiento de redes P2P son:",
          list: [
            "Troubleshooting por punto.",
            "Mediciones y reparación de pérdidas de potencia.",
            "Reactivación de red FTTH por corte.",
            "Migración en red activa en horario ventana.",
            "Reparación de red de planta externa.",
            "Adecuaciones civiles.",
          ],
        },
        {
          title: "Transmisión",
          path: require("@/assets/images/mantenimiento/svg/transmision.svg"),
          hover: false,
          hover_path: require("@/assets/images/mantenimiento/svg/transmision_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#635F63",
          content:
            "La tecnología actual nos ha dirigido a interconectar todos nuestros centros de operaciones, asumiendo el deber de colaborar a nuestros clientes, contamos con personal capacitado para realizar instalaciones y soporte en el área de transmisión que a continuación se describen: ",
          list: [
            "Instalación y configuración de Router / Switch, instalación de redes LAN y MAN.",
            "Instalación y configuración de equipos integrados OTDR para verificación del estado de la RED y Qos.",
            "Instalación y configuración de equipos de alto tráfico DWDM.",
            "Instalación y adecuaciones para gabinetes y rack.",
            "Certificación de UTP, FTP y STP. ",
          ],
        },
      ],
    };
  },
  methods: {
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
.dit-btn-mantenimiento {
  background-color: #635f63 !important;
  color: #fff !important;
  &:hover {
    background-color: #009ee2 !important;
    color: #fff !important;
  }
}
</style>