<template>
  <v-row justify="center" class="no-gutters">
    <v-col cols="8" class="text-center py-10">
      <p class="text-h4 dit-text-primary font-weight-bold">
        REGISTRA A TU EMPRESA
        <span class="dit-text-accent">PARA SER NUESTRO PROVEEDOR</span>
      </p>
    </v-col>
    <v-col cols="10" md="10" lg="8">
      <v-divider style="background-color: #009ee2" />
      <v-row class="pt-10 no-gutters" justify="center">
        <v-col cols="10" sm="10" md="7">
          <v-form ref="_form" v-model="valid" lazy-validation>
            <v-row class="">
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Nombre de la empresa"
                  v-model="new_supplier.name"
                  required
                  dense
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Nombre de persona de contacto"
                  v-model="new_supplier.contact_name"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Email"
                  v-model="new_supplier.contact_email"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  dense
                  label="Cuéntanos a que se dedica tu empresa"
                  v-model="new_supplier.message"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-textarea
              ></v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="text-center pb-15">
      <v-btn large class="dit-btn rounded-pill" @click="submit"
        >ENVIAR DATOS
      </v-btn>
      <v-snackbar v-model="snackbar" :timeout="3000" color="primary">
        {{ snackbar_text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      new_supplier: {},
      snackbar: false,
      snackbar_text: "Formulario enviado!.",
      valid: true,
    };
  },
  methods: {
    submit: function () {
      const self = this;
      if (this.$refs._form.validate()) {
        firebase
          .firestore()
          .collection("outsourcing_be_supplier")
          .add(this.new_supplier)
          .then(function () {
            self.$refs._form.reset();
            self.snackbar = true;
          });
        /*.catch(function(error) {
          });*/
      }
    },
  },
};
</script>

<style>
</style>