<template>
  <div
    :class="info.img_path ? 'pt-10 pb-10' : 'pt-15 pb-15'"
    style="
      background-color: #009ee2;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
    "
    :style="'background-image:url(' + info.img_path + ')'"
  >
    <v-row justify="center" align="center" class="no-gutters">
      <v-col cols="10" xl="6" lg="6" md="8" sm="8">
        <p
          class="text-h4 text-sm-h4 text-md-h4 text-lg-h3 font-weight-bold text-center mt-10"
          :class="info.img_path ? 'dit-text-primary' : 'white--text'"
        >
          {{ info.title }}
        </p>
        <p class="text-h5 dit-text-accent font-weight-bold text-center mt-10">
          {{ info.content }}
        </p>
        <v-btn
          large
          :color="info.img_path ? '#009EE2' : '#fff'"
          class="ma-2 rounded-pill"
          :class="info.img_path ? 'dit-btn' : 'dit-btn-alt'"
          :to="info.button_to"
        >
          {{ info.button_text }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {
      style: "background-image:" + this.info.img_path,
    };
  },
};
</script>

<style>
</style>