<template>
  <div>
    <v-row justify="center">
      <v-col cols="11" sm="10">
        <v-btn plain x-large to="/ventas" class="mt-10">
          <v-icon left>mdi-arrow-left</v-icon>
          Ir a Tienda
        </v-btn>
      </v-col>
      <v-col cols="10" md="10" lg="7">
        <v-stepper v-model="e6" vertical class="mb-10">
          <v-stepper-step :complete="e6 > 1" step="1" editable>
            Lista de productos
            <!--<small>Summarize if needed</small>-->
          </v-stepper-step>

          <v-stepper-content step="1" style="padding-bottom: 35px">
            <v-row>
              <v-col>
                <v-card elevation="0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Imagen</th>
                          <th class="text-left hidden-xs-only">Producto</th>
                          <th class="text-left">Cantidad</th>
                          <th class="text-left">Cancelar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in shop_cart" :key="index">
                          <td>
                            <v-img
                              :src="item.productImagePath"
                              height="100px"
                              width="100px"
                              contain
                              v-if="item.productImagePath"
                            ></v-img>
                            <v-img
                              :src="item.packageImagePath"
                              height="100px"
                              width="100px"
                              contain
                              v-else
                            ></v-img>
                          </td>
                          <td class="hidden-xs-only">{{ item.model }}</td>
                          <td>
                            <v-text-field
                              v-model="item.quantity"
                              hide-details
                              outlined
                              single-line
                              type="number"
                              style="max-width: 75px"
                              @input="edititem(index, item)"
                            />
                          </td>
                          <td>
                            <v-hover v-slot:default="{ hover }">
                              <v-btn
                                :color="hover ? 'primary' : 'grey'"
                                icon
                                @click="deleteitem(index)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-hover>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn color="primary" @click="e6 = 2">Siguiente</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-step step="2" editable :rules="[() => isSelected]"
            >Seleccionar Almacen<small
              >Seleccione el almacen mas cercano</small
            ></v-stepper-step
          >

          <v-stepper-content step="2">
            <v-card class="mb-12 elevation-0" style="padding: 10px">
              <v-select
                v-model="selected_warehouse"
                :items="warehousesList"
                item-text="name"
                item-value="id"
                label="Select"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-card>
            <v-btn color="primary" @click="e6 = 3">Siguiente</v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3" editable
            >Confirmar</v-stepper-step
          >

          <v-stepper-content step="3">
            <v-btn
              color="primary"
              :loading="confirmation_loading"
              :disabled="confirmation_loading"
              @click="addsale"
              class="mt-10"
              >Confirmar Cotización</v-btn
            >
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" vertical color="primary">
      Un ejecutivo te enviará un mail con la cotización solicitada.
    </v-snackbar>
  </div>
</template>
<script>
import Vue from "vue";
import firebase from "firebase";
import { mapState } from "vuex";
export default {
  data() {
    return {
      e6: 1,
      total_price: 0,
      warehousesList: [],
      isSelected: true,
      selected_warehouse: null,
      confirmation_loading: false,
      listofproductsinvoucher: [],
      listofpackagesinvoucher: [],
      snackbar: false,
      timeout: 2000,
    };
  },
  beforeMount() {
    this.getwarehouseslist();
    this.$store.commit("set_shop_cart");
  },
  computed: {
    ...mapState({
      user_profile: (state) => state.user_profile,
      is_loggedin: (state) => state.is_loggedin,
      shop_cart: (state) => state.shop_cart,
    }),
    totalprice: function () {
      let total = 0;
      this.shop_cart.forEach((item) => {
        total = parseInt(total) + parseInt(item.totalprice);
      });
      return total;
    },
  },
  methods: {
    getwarehouseslist: function () {
      const self = this;
      this.isWarehousesEmpty = false;
      firebase
        .firestore()
        .collection("warehouses")
        .where("enable", "==", true)
        .onSnapshot((querySnapshot) => {
          let warehouses = [];
          querySnapshot.forEach((doc) => {
            let warehouse = doc.data();
            warehouse.id = doc.id;
            warehouses.push(warehouse);
          });
          self.warehousesList = warehouses;
        });
    },
    edititem: function (index, item) {
      item.quantity = parseInt(item.quantity);
      item.totalprice = item.price * item.quantity;
      Vue.set(this.shop_cart, index, item);
      localStorage.setItem("shop_cart", JSON.stringify(this.shop_cart));
    },
    deleteitem: function (index) {
      this.shop_cart.splice(index, 1);
      localStorage.setItem("shop_cart", JSON.stringify(this.shop_cart));
      if (this.shop_cart.length < 1) {
        this.$router.push({ name: "Sales" });
      }
    },
    addsale: function () {
      const self = this;
      if (this.selected_warehouse) {
        self.isSelected = true;
        self.confirmation_loading = true;
        self.shop_cart.forEach((item) => {
          if (item.category === "PROMOCIONES") {
            self.listofpackagesinvoucher.push(item);
          } else {
            self.listofproductsinvoucher.push(item);
          }
        });
        firebase
          .firestore()
          .collection("sales")
          .add({
            salevoucher: Math.floor(Math.random() * 10000000) + 1,
            date: new Date(),
            clientid: self.user_profile.id,
            status: "Cotización",
            price: Number(self.totalprice),
            warehouse: self.selected_warehouse,
            listofproductsinvoucher: self.listofproductsinvoucher,
            listofpackagesinvoucher: self.listofpackagesinvoucher,
          })
          .then(function () {
            self.snackbar = true;
            setTimeout(() => {
              self.$store.commit("clear_shop_cart");
              self.$router.push({ name: "Sales" });
            }, 3500);
          });
      } else {
        self.isSelected = false;
      }
      /**/
    },
  },
};
</script>