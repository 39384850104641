<template>
  <v-row
    class="no-gutters"
    justify="center"
    style="background-color: #e5e5e5"
    id="scrolled"
  >
    <v-col cols="10">
      <p
        class="
          text-h5 text-sm-h4 text-md-h4 text-lg-h3
          dit-text-primary
          font-weight-bold
          text-center
          mt-10
          mb-10
        "
      >
        ¿Qué ofrecemos?
      </p></v-col
    >
    <v-col cols="10" sm="10" md="8" class="mb-15">
      <v-row justify="center">
        <v-col
          cols="10"
          sm="4"
          md="4"
          xl="3"
          v-for="item in services"
          :key="item.path"
        >
          <v-card
            :style="item.style"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            :color="item.hover ? item.color : 'transparent'"
            :height="
              $vuetify.breakpoint.xs
                ? '240px'
                : $vuetify.breakpoint.sm
                ? '210px'
                : $vuetify.breakpoint.md
                ? '220px'
                : $vuetify.breakpoint.lg
                ? '300px'
                : '300px'
            "
            v-on:click="scrollfade(item.title)"
          >
            <v-row align="center" justify="center">
              <v-col cols="8">
                <v-img
                  :src="item.hover ? item.hover_path : item.path"
                  class="ml-5 mr-5 mt-5"
                />
              </v-col>
              <v-col cols="10">
                <p
                  class="
                    text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 text-center
                    font-weight-bold
                  "
                  :style="item.hover ? 'color:white' : 'color:#1B4864'"
                >
                  {{ item.title }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="background-color: #ffffff">
      <v-row justify="center" class="mt-15 mb-15 no-gutters">
        <v-col
          cols="8"
          v-for="item in services"
          :key="item.path"
          :id="item.title"
        >
          <v-card :style="item.style">
            <v-row align="start" justify="center" class="mt-5 mb-5">
              <v-col cols="10" sm="8" md="4" lg="3" xl="3">
                <v-img :src="item.path" class="ml-10 mr-10" />
              </v-col>
              <v-col cols="8">
                <p
                  class="
                    text-sm-h6 text-md-h5 text-lg-h4 text-left
                    font-weight-bold
                  "
                  style="color: #009ee2"
                >
                  {{ item.title }}
                </p>
                <p>
                  {{ item.content }}
                </p>
                <ul>
                  <li v-for="l in item.list" :key="l">{{ l }}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card>
          <v-col cols="12" style="text-align: center">
            <v-btn
              large
              color="#009EE2"
              class="mt-10 mb-5 dit-btn-carrier white--text rounded-pill"
              to="/contacto"
            >
              Contactar
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Fibra óptica",
          path: require("@/assets/images/carrier/svg/fibraoptica.svg"),
          hover: false,
          hover_path: require("@/assets/images/carrier/svg/fibraoptica_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#1B4864",
          content:
            "Contamos con personal, equipos y logística listos para ejecutar proyectos de alta exigencia en los siguientes servicios: ",
          list: [
            "Survey y diseño de redes",
            "Gestión de permisos con entidades públicas citada, por ejemplo:  eléctricas, ABT, ABC, alcaldías y gobernaciones.",
            "Implementación y construcción de redes punto a punto, multipunto FTTH, FTTB  (edificios) y conexiones a abonados. ",
            "Diseño e implementación de redes HFC",
            "Certificados de redes",
            "Mediciones OTDR",
            "Mediciones de Potencia",
            "Mediciones de PMD",
            "Mediciones de RFC",
            "Documentación AsBuilt del enlace o sitio. ",
          ],
        },
        {
          title: "Energía",
          path: require("@/assets/images/carrier/svg/energia.svg"),
          hover: false,
          hover_path: require("@/assets/images/carrier/svg/energia_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#1B4864",
          content:
            "Contamos con un grupo de profesionales expertos y certificados en el manejo, instalación y mantenimiento de equipos de energía en el área de telecomunicaciones en las siguientes áreas:",
          list: [
            "Site survey y diseño de nuevos sistemas de energía híbridos.",
            "Instalación y comisionamiento de UPS (Sistemas de alimentación Ininterrumpida).",
            "Instalación y comisionamiento de diesel generador.",
            "Montaje y puesta en marcha de paneles solares en distintos escenarios.",
            "Comisionamiento e instalación de gabinetes de energía.",
            "Instalación de banco baterías.",
          ],
        },

        {
          title: "Obras civiles",
          path: require("@/assets/images/carrier/svg/obrasciviles.svg"),
          hover: false,
          hover_path: require("@/assets/images/carrier/svg/obrasciviles_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#1B4864",
          content:
            "Contamos con el personal y logística para la construcción de estaciones completas greenfield y rooftop y el montaje de torres para sistemas de telecomunicaciones, nuestros servicios son:",
          list: [
            "Elaboración de planos AS-Build Greenfield y Roof Top.",
            "Estudio geotécnico del suelo para fundación.",
            "Desmonte, preparación y nivelación del terreno.",
            "Excavación de terreno manual (suelo semiduro, duro y rocoso).",
            "Replanteo de obra.",
            "Construcción de cimientos y sobrecimientos.",
            "Construcción de lozas.",
            "Construcción de muro de bloque de cemento y/o provisión e instalación de malla olímpica.",
            "Instalación de acometida y tablero eléctrico.",
            "Sistemas de puesta a tierra.",
            "Instalación de pilastra.",
            "Instalación de tablero de control de baliza.",
          ],
        },
        {
          title: "Microondas y Radio Bases",
          path: require("@/assets/images/carrier/svg/microondas.svg"),
          hover: false,
          hover_path: require("@/assets/images/carrier/svg/microondas_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#1B4864",
          content:
            "Nuestros profesionales cuentan con la destreza y experiencia necesaria para el montaje, instalación, integración y mantenimiento de equipos de telefonía celular, contamos con:",
          list: [
            "Site survey, relevamiento, y diseño estaciones base de telefonía celular.",
            "Instalación, expansión, SWAP y desmontaje de equipos radio bases, antenas, Dual-Band.",
            "Instalación, reemplazo y desmontaje de radio bases completas.",
            "Instalación, SWAP y desmontaje de Micro BTS.",
            "Instalación de BSS/RNC.",
            "Migración de tecnologías.",
            "Drive test de radio frecuencia.",
            "Site Survey LOS (Line Of Sight) y relevamiento de estaciones para implementación de nuevos equipos.",
            "Comisionamiento, Instalación, expansión y reemplazo de equipos de microondas de las series RTN300-900.",
            "Instalación, alineamiento, swap, desmontaje y reemplazo de enlaces de microondas en diferentes escenarios a nivel nacional.",
            "Migración de servicio y tecnologías.",
            "Adecuación en torres, instalación y movimientos de soportes.",
          ],
        },
        {
          title: "Transmisión",
          path: require("@/assets/images/carrier/svg/transmision.svg"),
          hover: false,
          hover_path: require("@/assets/images/carrier/svg/transmision_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#1B4864",
          content:
            "La tecnología actual nos ha dirigido a interconectar todos nuestros centros de operaciones, asumiendo el deber de colaborar a nuestros clientes contamos con personal capacitado para realizar instalaciones y soporte en el área de transmisión que a continuación se describen: ",
          list: [
            "Instalación y configuración de Router / Switch, instalación de redes LAN y tMAN.",
            "Instalación y configuración de equipos integrados OTDR para verificación del estado de la RED y Qos.",
            "Instalación y configuración de equipos de alto tráfico DWDM. ",
            "Instalación y adecuaciones para gabinetes y rack.",
            "Certificación de UTP, FTP y STP. ",
          ],
        },
      ],
    };
  },
  methods: {
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
.dit-btn-carrier {
  background-color: #1b4864 !important;
  color: #fff !important;
  &:hover {
    background-color: #009ee2 !important;
    color: #fff !important;
  }
}

</style>