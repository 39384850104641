<template>
  <v-row justify="center" class="no-gutters">
    <v-col cols="10">
      <p
        class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10"
      >
        Nuestra <span class="dit-text-accent">propuesta</span> de valor
      </p>
    </v-col>
    <v-col cols="12" class="mb-15 hidden-sm-and-up">
      <slider ref="slider" :options="options">
        <!-- slideritem wrapped package with the components you need -->
        <slideritem
          v-for="(item, index) in proposalList"
          :key="index"
          :style="slider_style"
        >
          <v-card
            class="ma-5"
            color="#E5E5E5"
            dark
            style="border-radius: 50px"
            height="300px"
          >
            <v-img :src="item.image" class="mt-5 ml-5 mr-5"></v-img>

            <v-card-title class="carrousel_title">{{
              item.title
            }}</v-card-title>
          </v-card>
        </slideritem>
        <!-- Customizable loading -->
        <div slot="loading">loading...</div>
      </slider>
    </v-col>
    <v-col cols="12" sm="10" md="12" lg="10" class="mb-15 hidden-xs-only">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="4"
          md="2"
          v-for="item in proposalList"
          :key="item.path"
        >
          <v-card
            style="border-radius: 50px"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            class="proposalitems pa-2"
            color="#E5E5E5"
            :height="
              $vuetify.breakpoint.xs
                ? '250px'
                : $vuetify.breakpoint.sm
                ? '210px'
                : $vuetify.breakpoint.md
                ? '200px'
                : $vuetify.breakpoint.lg
                ? '230px'
                : '305px'
            "
          >
            <v-img :src="item.hover ? item.image_hover : item.image" contain/>
            <v-card-title
              class="carrousel_title_md"
              :style="
                $vuetify.breakpoint.sm
                  ? 'font-size:17px; line-height: 15px;'
                  : $vuetify.breakpoint.md
                  ? 'font-size:17px; line-height: 15px;'
                  : $vuetify.breakpoint.lg
                  ? 'font-size:21px; line-height: 20px;'
                  : 'font-size:27px; line-height: 25px;'
              "
              >{{ item.title }}</v-card-title
            >
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  data() {
    return {
      //data list [array]
      slider_style: {
        width: "70%",
        "margin-right": "5%",
        "border-radius": "50px",
      },

      proposalList: [
        {
          title: "Confianza mutua con los clientes",
          hover: false,
          image: require("@/assets/images/inicio/svg/propuesta/confianza.svg"),
          image_hover: require("@/assets/images/inicio/svg/propuesta/confianza_hover.svg"),
        },
        {
          title: "Eficiencia en tiempos de ejecución",
          hover: false,
          image: require("@/assets/images/inicio/svg/propuesta/eficiencia.svg"),
          image_hover: require("@/assets/images/inicio/svg/propuesta/eficiencia_hover.svg"),
        },
        {
          title: "Atención a tiempo completo",
          hover: false,
          image: require("@/assets/images/inicio/svg/propuesta/disponibilidad.svg"),
          image_hover: require("@/assets/images/inicio/svg/propuesta/disponibilidad_hover.svg"),
        },
        {
          title: "Experiencia en ejecución de proyectos",
          hover: false,
          image: require("@/assets/images/inicio/svg/propuesta/ejecucion.svg"),
          image_hover: require("@/assets/images/inicio/svg/propuesta/ejecucion_hover.svg"),
        },
        {
          title: "Optimización de recursos por proyecto",
          hover: false,
          image: require("@/assets/images/inicio/svg/propuesta/optimizacion.svg"),
          image_hover: require("@/assets/images/inicio/svg/propuesta/optimizacion_hover.svg"),
        },
      ],
      //Slider configuration [obj]
      options: {
        currentPage: 1,
        pagination: false,
        centeredSlides: true,
      },
    };
  },
  components: {
    slider,
    slideritem,
  },
};
</script>
<style lang="scss">

</style>