<template>
  <v-row justify="center" style="background-color: #e5e5e5" class="no-gutters">
    <v-col cols="10">
      <p
        class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10"
      >
        Empresas que <span class="dit-text-accent">confían</span> en nosotros
      </p>
    </v-col>
    <v-col cols="10" md="10">
      <v-row class="ma-10">
        <v-col
          cols="6"
          md="2"
          v-for="item in clientsList"
          :key="item.url"
          class="d-flex child-flex"
          :class=" $vuetify.breakpoint.xs ? '' : item.hover ? '' : 'pa-5'"
          @mouseover="item.hover = true"
          @mouseleave="item.hover = false"
        >
          <v-img :src="item.url"></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      clientsList: [
        {
          url: require("@/assets/images/inicio/png/comteco.png"),
          hover: false,
        },
        {
          url: require("@/assets/images/inicio/png/cre.png"),
          hover: false,
        },
        {
          url: require("@/assets/images/inicio/png/entel.png"),
          hover: false,
        },
        {
          url: require("@/assets/images/inicio/png/huawei.png"),
          hover: false,
        },
        {
          url: require("@/assets/images/inicio/png/tigo.png"),
          hover: false,
        },
        {
          url: require("@/assets/images/inicio/png/viva.png"),
          hover: false,
        },
      ],
    };
  },
};
</script>

<style>
</style>