<template>
  <v-row
    class="dit-hero-sales no-gutters"
    :style="
      $vuetify.breakpoint.xs
        ? 'height:500px'
        : $vuetify.breakpoint.sm
        ? 'height:455px'
        : $vuetify.breakpoint.md
        ? 'height:450px'
        : $vuetify.breakpoint.lg
        ? 'height:370px'
        : $vuetify.breakpoint.xl
        ? 'height:470px'
        : 'height:490px'
    "
    align="center"
    justify="center"
  >
    <v-col cols="11" sm="10" md="8" lg="6">
      <v-row class="no-gutters pt-15" justify="center" align="center">
        <v-col
          cols="8"
          class="white--text text-center mb-9 text-h4 text-md-h3"
        >
          {{ title }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      title: "Encuentra el material que estás buscando y  pide una cotización",
      user_profile: [],
      logged_in: false,
    };
  },
  mounted() {
    if (localStorage.getItem("user_profile")) {
      try {
        this.user_profile = JSON.parse(localStorage.getItem("user_profile"));
        this.logged_in = true;
      } catch (e) {
        localStorage.removeItem("user_profile");
      }
    }
  },
  methods: {
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
</style>