<template>
  <v-row
    class="no-gutters"
    justify="center"
    style="background-color: rgba(170, 170, 193, 0.3)"
    id="scrolled"
  >
    <v-col cols="10">
      <p
        class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10 mb-10"
      >
        ÁREAS DE SERVICIOS <span class="dit-text-accent">EN TELECOMUNICACIONES</span>
      </p>
    </v-col>
    <v-col cols="10" sm="10" md="10" lg="9" class="mb-15">
      <v-row justify="center">
        <v-col
          cols="10"
          sm="5"
          md="4"
          lg="3"
          xl="3"
          v-for="item in services"
          :key="item.path"
        >
          <v-card
            :style="item.style"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            :color="item.hover ? item.color : 'transparent'"
            :height="
              $vuetify.breakpoint.xs
                ? '250px'
                : $vuetify.breakpoint.sm
                ? '240px'
                : $vuetify.breakpoint.md
                ? '260px'
                : $vuetify.breakpoint.lg
                ? '280px'
                : '340px'
            "
          >
            <v-row align="center" justify="center">
              <v-col cols="8">
                <v-img
                  :src="item.hover ? item.hover_path : item.path"
                  class="ml-3 mr-3 mt-7"
                />
              </v-col>
              <v-col cols="10">
                <p
                  class="text-sm-h6 text-md-h6 text-lg-h6 text-xl-h5 text-center font-weight-bold"
                  :style="item.hover ? 'color:white' : 'color:#013156'"
                >
                  {{ item.title }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "TRANSMISIÓN",
          path: require("@/assets/images/outsourcing/svg/transmision.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/transmision_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },
        {
          title: "RADIOFRECUENCIA",
          path: require("@/assets/images/outsourcing/svg/radiofrecuencia.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/radiofrecuencia_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },

        {
          title: "WIRELESS",
          path: require("@/assets/images/outsourcing/svg/wireless.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/wireless_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },
        {
          title: "MICROWAVE",
          path: require("@/assets/images/outsourcing/svg/microwave.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/microwave_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },
        {
          title: "FIBRA ÓPTICA",
          path: require("@/assets/images/outsourcing/svg/fibraoptica.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/fibraoptica_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },
        {
          title: "OBRAS CIVILES",
          path: require("@/assets/images/outsourcing/svg/obrasciviles.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/obrasciviles_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },
        {
          title: "ENERGÍA",
          path: require("@/assets/images/outsourcing/svg/energia.svg"),
          hover: false,
          hover_path: require("@/assets/images/outsourcing/svg/energia_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#009EE2",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.dit-btn-outsourcing {
  background-color: #635f63 !important;
  color: #fff !important;
  &:hover {
    background-color: #009ee2 !important;
    color: #fff !important;
  }
}
</style>