<template>
  <v-row
    class="dit-hero-contacto no-gutters pb-15"
    align="start"
    justify="center"
  >
    <v-col cols="10" sm="10" lg="5">
      <Contact :info="formitems" />
    </v-col>
  </v-row>
</template>

<script>
import Contact from "../components/forms/contact.vue";
export default {
  data() {
    return {
      title: "Deja tus datos y un ejecutivo se pondrá en contacto pronto ",
      form: [],
      form_title: "¿Te interesa alguno de nuestros servicios?",
      formlabels: [
        {
          label: "Empresa:",
          model: "company",
        },
        {
          label: "Teléfono/Celular:",
          model: "phone",
        },
        {
          label: "Nombres:",
          model: "name",
        },
        {
          label: "Apellidos:",
          model: "lastname",
        },
        {
          label: "Correo:",
          model: "email",
        },
      ],
      formitems: {
        title: "¡Déjanos tus datos para enviarte más información!",
        divisions: [
          {
            title: "DitCarrier",
            title_lowercase: "ditcarrier",
            areas: [
              "Fibra óptica",
              "Energía",
              "Obras civiles",
              "Microondas y Radio Bases",
              "Transmisión",
            ],
          },
          {
            title: "DitEnterprise",
            title_lowercase: "ditenterprise",
            areas: [
              "Cableado estructurado",
              "Fibra óptica",
              "Redes, seguridad y transmisión",
              "Obras civiles",
              "Proyectos dedicados",
              "Energía",
            ],
          },
          {
            title: "DitTransporte",
            title_lowercase: "dittransporte",
            areas: [
              "Almacenaje",
              "Inventario",
              "Empaquetado",
              "Courrier",
              "Puerta a puerta",
              "Carga",
              "Distribución",
            ],
          },
          {
            title: "DitMantenimiento",
            title_lowercase: "ditmantenimiento",
            areas: [
              "Redes FTTH",
              "Obras civiles",
              "Red de transporte",
              "Transmisión",
            ],
          },
          {
            title: "DitOutsourcing",
            title_lowercase: "ditoutsourcing",
            areas: [
              "Reclutamiento ",
              "Selección",
              "Evaluación",
              "Contratación",
              "Administración de nómina",
              "Postular a un empleo",
            ],
          },
        ],
      },
    };
  },
  components: {
    Contact,
  },
  methods: {
    validate: function () {
      console.log(this.form);
    },
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
</style>