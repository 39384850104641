import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DitCarrier from '../views/Divisions/DitCarrier.vue'
import DitEnterprise from '../views/Divisions/DitEnterprise.vue'
import DitMantenimiento from '../views/Divisions/DitMantenimiento.vue'
import DitOutsourcing from '../views/Divisions/DitOutsourcing.vue'
import DitTransporte from '../views/Divisions/DitTransporte.vue'
import Contact from '../views/Contact.vue'
import LookingStaff from "../components/forms/lookingstaff.vue";
import LookingJob from "../components/forms/lookingjob.vue";
import OurSupplier from "../components/forms/oursupplier.vue";
import DO_telecommunications from '../components/divisions/outsourcing/services/telecommunications.vue';
import Sales from '../views/sales.vue'
import MyOrders from '../components/sales/myorders.vue';
import MyCart from '../components/sales/cart.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/ditcarrier',
    name: 'DitCarrier',
    component: DitCarrier

  },
  {
    path: '/ditenterprise',
    name: 'DitEnterprise',
    component: DitEnterprise

  },
  {
    path: '/ditmantenimiento',
    name: 'DitMantenimiento',
    component: DitMantenimiento

  },
  {
    path: '/ditoutsourcing',
    name: 'DitOutsourcing',
    component: DitOutsourcing

  },
  {
    path: '/ditoutsourcing/telecomunicaciones',
    name: 'DO_telecommunications',
    component: DO_telecommunications

  },
  {
    path: '/dittransporte',
    name: 'DitTransporte',
    component: DitTransporte

  },
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact,

  },
  {
    path: '/busco_personal',
    name: 'LookingStaff',
    component: LookingStaff,

  },
  {
    path: '/nuestro_proveedor',
    name: 'OurSupplier',
    component: OurSupplier,

  },
  {
    path: '/nuevo_personal',
    name: 'LookingJob',
    component: LookingJob,

  },
  {
    path: '/ventas',
    name: 'Sales',
    component: Sales,

  },
  {
    path: '/ventas/mis_ordenes',
    name: 'MyOrders',
    component: MyOrders
  },
  {
    path: '/ventas/carrito',
    name: 'MyCart',
    component: MyCart
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
