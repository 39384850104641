var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"no-gutters",staticStyle:{"background-color":"rgba(170, 170, 193, 0.3)"},attrs:{"justify":"center","id":"scrolled"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10 mb-10"},[_vm._v(" ÁREAS DE SERVICIOS "),_c('span',{staticClass:"dit-text-accent"},[_vm._v("EN TELECOMUNICACIONES")])])]),_c('v-col',{staticClass:"mb-15",attrs:{"cols":"10","sm":"10","md":"10","lg":"9"}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.services),function(item){return _c('v-col',{key:item.path,attrs:{"cols":"10","sm":"5","md":"4","lg":"3","xl":"3"}},[_c('v-card',{style:(item.style),attrs:{"color":item.hover ? item.color : 'transparent',"height":_vm.$vuetify.breakpoint.xs
              ? '250px'
              : _vm.$vuetify.breakpoint.sm
              ? '240px'
              : _vm.$vuetify.breakpoint.md
              ? '260px'
              : _vm.$vuetify.breakpoint.lg
              ? '280px'
              : '340px'},on:{"mouseover":function($event){item.hover = true},"mouseleave":function($event){item.hover = false}}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-img',{staticClass:"ml-3 mr-3 mt-7",attrs:{"src":item.hover ? item.hover_path : item.path}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"text-sm-h6 text-md-h6 text-lg-h6 text-xl-h5 text-center font-weight-bold",style:(item.hover ? 'color:white' : 'color:#013156')},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }