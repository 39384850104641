<template>
  <section>
    <v-fab-transition class="hidden-xs-only">
      <v-btn
        :color="btn.color"
        large
        dark
        bottom
        right
        fixed
        :href="btn.href"
        target="_blank"
        class="rounded-pill hidden-xs-only"
      >
        <v-icon left dark>{{ btn.icon }}</v-icon> Consulta
      </v-btn>
    </v-fab-transition>
    <v-fab-transition class="hidden-sm-and-up">
      <v-btn
        :color="btn.color"
        fab
        dark
        bottom
        right
        fixed
        :href="btn.href"
        target="_blank"
        class="hidden-sm-and-up"
      >
        <v-icon>{{ btn.icon }}</v-icon>
      </v-btn>
    </v-fab-transition>
  </section>
</template>

<script>
export default {
  data() {
    return {
      btn: {
        icon: "mdi-whatsapp",
        title: "(591) 697 01203",
        color: "#25D366",
        href: "https://wa.me/59169701203",
      },
    };
  },
};
</script>

<style>
</style>