<template>
  <v-row justify="center">
    <v-col cols="11" sm="10">
      <v-btn plain x-large to='/ventas' class="mt-10">
        <v-icon left>mdi-arrow-left</v-icon>
        Ir a Tienda
      </v-btn>
    </v-col>
    <v-col cols="10" lg="6" style="min-height: 600px">
      <v-banner
        two-line
        v-if="my_orders.length == 0"
        class="mt-15"
        elevation="0"
      >
        <v-avatar slot="icon" size="40">
          <v-icon color="warning"> mdi-alert-box </v-icon>
        </v-avatar>
        Aun no tienes Ningun pedido...pero puedes ver nuestro catalogo aqui
        <template v-slot:actions>
          <v-btn outlined color="primary" to="/ventas">Ir a tienda</v-btn>
        </template>
      </v-banner>
      <div v-else>
        <v-row justify="center">
          <v-col
            cols="12"
            class="headline primary--text font-weight-black mt-10"
            >MIS PEDIDOS</v-col
          >
          <v-col cols="12">
            <v-expansion-panels v-for="item in my_orders" v-bind:key="item.id">
              <v-expansion-panel>
                <v-expansion-panel-header
                  disable-icon-rotate
                  class="font-weight-black"
                >
                  <v-row no-gutters>
                    <v-col cols="3">VT-{{ item.salevoucher }}</v-col>
                    <v-col cols="9" class="text--secondary">
                      <v-fade-transition leave-absolute>
                        <v-row no-gutters style="width: 100%">
                          <v-col
                            cols="6"
                            class="font-weight-light text-body-1"
                            >{{ converttimestamp(item.date) }}</v-col
                          >
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>

                  <template v-slot:actions>
                    <v-icon color="teal">mdi-check</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="font-weight-black text-h6 mt-5 mb-5">
                    PRODUCTOS
                  </div>
                  <v-simple-table v-if="!item.listofproductsinvoucher.empty">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Imagen</th>
                          <th class="text-left hidden-xs-only">Producto</th>
                          <th class="text-left">Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(p, index) in item.listofproductsinvoucher"
                          :key="index"
                        >
                          <td>
                            <v-img
                              :src="p.productImagePath"
                              height="100px"
                              width="100px"
                              contain
                              v-if="p.productImagePath"
                            ></v-img>
                            <v-img
                              :src="p.packageImagePath"
                              height="100px"
                              width="100px"
                              contain
                              v-else
                            ></v-img>
                          </td>
                          <td class="hidden-xs-only">{{ p.model }}</td>
                          <td>
                            {{p.quantity}}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="font-weight-light text-body-2" v-else>
                    Ningun producto Registrado
                  </div>
                  <div class="font-weight-black text-h6 mt-5 mb-5">
                    PAQUETES
                  </div>
                  <v-simple-table v-if="!item.listofproductsinvoucher.empty">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Imagen</th>
                          <th class="text-left hidden-xs-only">Producto</th>
                          <th class="text-left">Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(p, index) in item.listofpackagesinvoucher"
                          :key="index"
                        >
                          <td>
                            <v-img
                              :src="p.productImagePath"
                              height="100px"
                              width="100px"
                              contain
                              v-if="p.productImagePath"
                            ></v-img>
                            <v-img
                              :src="p.packageImagePath"
                              height="100px"
                              width="100px"
                              contain
                              v-else
                            ></v-img>
                          </td>
                          <td class="hidden-xs-only">{{ p.name }}</td>
                          <td>
                            {{p.quantity}}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="font-weight-light text-body-2" v-else>
                    Ningun producto Registrado
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "firebase";
import moment from "moment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      my_orders: [],
    };
  },
  computed: {
    ...mapState({
      user_profile: (state) => state.user_profile,
      is_loggedin: (state) => state.is_loggedin,
    }),
  },
  mounted() {
    if (localStorage.getItem("user_profile")) {
      this.$store.commit(
        "login",
        JSON.parse(localStorage.getItem("user_profile"))
      );
    }
    if (localStorage.getItem("shop_cart")) {
      try {
        this.shop_cart = JSON.parse(localStorage.getItem("shop_cart"));
      } catch (e) {
        localStorage.removeItem("shop_cart");
      }
    }
    this.getmyorders();
  },
  methods: {
    getmyorders: function () {
      const self = this;
      firebase
        .firestore()
        .collection("sales")
        .where("clientid", "==", self.user_profile.id)
        .onSnapshot((querySnapshot) => {
          let orders = [];
          querySnapshot.forEach((doc) => {
            let order = doc.data();
            order.id = doc.id;
            orders.push(order);
          });
          self.my_orders = orders;
        });
      console.log(self.my_orders);
    },
    converttimestamp: function (date) {
      return moment(date.seconds * 1000).format("DD/MM/YYYY");
    },
    goTo: function (destination) {
      this.$router.push({ name: destination });
    },
  },
};
</script>