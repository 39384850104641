<template>
  <v-row class="no-gutters" justify="center" style="background-color: #e5e5e5" id="scrolled">
    <v-col cols="10">
      <p
        class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10 mb-10"
      >
        6 VENTAJAS DE <br /><span class="dit-text-accent"
          >DIT TRANSPORTE Y ALMACENAJE</span
        >
      </p></v-col
    >
    <v-col cols="10" sm="10" md="8" class="mt-10 mb-15">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          v-for="item in services"
          :key="item.path"
        >
          <v-row
            align="center"
            justify="center"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
          >
            <v-col cols="10" class="text-center">
              <v-avatar
                :color="item.hover ? item.hover_color : item.color"
                :size="avatar_size"
              >
                <v-img
                  :src="item.path"
                  :height="img_size"
                  :width="img_size"
                  contain
                />
              </v-avatar>
            </v-col>
            <v-col cols="10">
              <p
                class="text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 text-center font-weight-bold"
                style="color: #003b77"
              >
                {{ item.title }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      avatar_size: this.$vuetify.breakpoint.xs
        ? "240px"
        : this.$vuetify.breakpoint.sm
        ? "240px"
        : this.$vuetify.breakpoint.md
        ? "200px"
        : this.$vuetify.breakpoint.lg
        ? "240px"
        : "240px",
      img_size: this.$vuetify.breakpoint.xs
        ? "150px"
        : this.$vuetify.breakpoint.sm
        ? "150px"
        : this.$vuetify.breakpoint.md
        ? "130px"
        : this.$vuetify.breakpoint.lg
        ? "150px"
        : "150px",
      services: [
        {
          title: "Planificación de requerimientos",
          path: require("@/assets/images/transporte/svg/planificacion.svg"),
          hover: false,
          hover_color: "#003B77",
          color: "#009EE2",
        },
        {
          title: "Procesos de inventario",
          path: require("@/assets/images/transporte/svg/procesos.svg"),
          hover: false,
          hover_color: "#003B77",
          color: "#009EE2",
        },
        {
          title: "Soporte técnico",
          path: require("@/assets/images/transporte/svg/soporte.svg"),
          hover: false,
          hover_color: "#003B77",
          color: "#009EE2",
        },
        {
          title: "Almacenaje seguro",
          path: require("@/assets/images/transporte/svg/almacenaje.svg"),
          hover: false,
          hover_color: "#003B77",
          color: "#009EE2",
        },
        {
          title: "Horarios flexibles",
          path: require("@/assets/images/transporte/svg/horarios.svg"),
          hover: false,
          hover_color: "#003B77",
          color: "#009EE2",
        },
        {
          title: "Personal calificado",
          path: require("@/assets/images/transporte/svg/personal.svg"),
          hover: false,
          hover_color: "#003B77",
          color: "#009EE2",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>