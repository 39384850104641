import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyAM9rr4WxJnk1GD1Hcyce93lM7jWq_guX0",
    authDomain: "ditgroup-3dc1a.firebaseapp.com",
    databaseURL: "https://ditgroup-3dc1a.firebaseio.com",
    projectId: "ditgroup-3dc1a",
    storageBucket: "ditgroup-3dc1a.appspot.com",
    messagingSenderId: "588567016980",
    appId: "1:588567016980:web:1ef736474f410083355901",
    measurementId: "G-R7E2PHV5VB"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const clientsCollection = db.collection('clients')
const productsCollection = db.collection('products')
const packagesCollection = db.collection('packages')
const stockCollection = db.collection('stock')
const warehousesCollection = db.collection('warehouses')
const cartCollection = db.collection('carts')

// export utils/refs
export {
    db,
    auth,
    usersCollection,
    clientsCollection,
    productsCollection,
    packagesCollection,
    stockCollection,
    warehousesCollection,
    cartCollection
}