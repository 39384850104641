<template>
  <v-row justify="center" class="no-gutters">
    <v-col cols="10" md="6" class="text-center pt-15 no-gutters">
      <p class="text-h4 dit-text-primary font-weight-bold">
        SOLICITA
        <span class="dit-text-accent">EL SERVICIO QUE NECESITAS</span>
      </p>
      <p class="text-body-1 mx-sm-15">
        Llena este formulario para solicitar más información acerca de los
        servicios de outsourcing de DIT Group.
      </p>
    </v-col>
    <v-col cols="10" class="my-10">
      <v-divider style="background-color: #009ee2" />
    </v-col>
    <v-col cols="10" md="10" lg="8">
      <v-form ref="_form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Nombre de la empresa:"
              v-model="new_staff.name"
              required
              :rules="[(v) => !!v || 'Campo Requerido']"
            ></v-text-field
          ></v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Dirección de la empresa:"
              v-model="new_staff.address"
              required
              :rules="[(v) => !!v || 'Campo Requerido']"
            ></v-text-field
          ></v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Ciudad:"
              v-model="new_staff.city"
              required
              :rules="[(v) => !!v || 'Campo Requerido']"
            ></v-text-field
          ></v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Nombre de persona de contacto:"
              v-model="new_staff.contact_name"
              required
              :rules="[(v) => !!v || 'Campo Requerido']"
            ></v-text-field
          ></v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Email:"
              v-model="new_staff.email"
              required
              :rules="[(v) => !!v || 'Campo Requerido']"
            ></v-text-field
          ></v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Teléfono:"
              v-model="new_staff.phone"
              required
              :rules="[(v) => !!v || 'Campo Requerido']"
            ></v-text-field
          ></v-col>
          <v-col>
            <v-select
              dense
              :items="services"
              v-model="new_staff.area"
              label="¿En qué area necesitas personal?"
              outlined
              class="black-text"
              :rules="[(v) => !!v || '*Requerido']"
              required
            ></v-select>
          </v-col>
          <v-col cols="12">
            <p class="font-weight-bold">¿Qué servicio necesitas?</p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              v-model="new_staff.recruitment"
              label="Reclutamiento"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              v-model="new_staff.selection"
              label="Selección"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              v-model="new_staff.evaluation"
              label="Evaluación"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              v-model="new_staff.hiring"
              label="Contratación"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              v-model="new_staff.payroll"
              label="Administración de nómina"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12" class="text-center py-15">
      <v-btn large class="dit-btn rounded-pill" @click="submit"
        >ENVIAR DATOS
      </v-btn>
      <v-snackbar v-model="snackbar" :timeout="3000" color="primary">
        {{ snackbar_text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      new_staff: {},
      services: [
        "Ingeniería en Telecomunicaciones",
        "Ingeniería Médica",
        "Ingeniería Petrolera",
        "Ingeniería Industrial",
        "Ingeniería Civil",
      ],
      snackbar: false,
      snackbar_text: "Formulario enviado!.",
      valid: true,
    };
  },
  methods: {
    submit: function () {
      const self = this;
      if (this.$refs._form.validate()) {
        firebase
          .firestore()
          .collection("outsourcing_looking_staff")
          .add(this.new_staff)
          .then(function () {
            self.$refs._form.reset();
            self.snackbar = true;
          });
        /*.catch(function(error) {
          });*/
      }
    },
  },
};
</script>

<style lang="scss">
.v-icon__svg {
  color: #009ee2;
}
</style>