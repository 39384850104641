<template>
  <v-app>
    <v-container fluid class="pa-0">
      <NavBar />
      <v-main>
        <router-view />
      </v-main>
      <Footer />
      <Floatingbtn />
    </v-container>
  </v-app>
</template>

<script>
import NavBar from "./components/nav/navbar";
import Footer from "./components/nav/footer";
import Floatingbtn from "./components/nav/floatingbtn.vue";
export default {
  name: "App",
  components: { NavBar, Footer, Floatingbtn },
  data: () => ({
    //
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "DitGroup SRL";
      },
    },
  },
};
</script>
<style lang="scss">
</style>