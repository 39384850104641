import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
          light: {
            primary: '#009EE2',
            secondary: '#b0bec5',
            accent: '#013156',
            error: '#b71c1c',
            anchor: '#8c9eff',
          },
        },
      },
});
