var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"dit-hero-sales no-gutters",style:(_vm.$vuetify.breakpoint.xs
      ? 'height:500px'
      : _vm.$vuetify.breakpoint.sm
      ? 'height:455px'
      : _vm.$vuetify.breakpoint.md
      ? 'height:450px'
      : _vm.$vuetify.breakpoint.lg
      ? 'height:370px'
      : _vm.$vuetify.breakpoint.xl
      ? 'height:470px'
      : 'height:490px'),attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"11","sm":"10","md":"8","lg":"6"}},[_c('v-row',{staticClass:"no-gutters pt-15",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"white--text text-center mb-9 text-h4 text-md-h3",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }