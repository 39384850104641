<template>
  <div>
    <Hero />
    <Store />
  </div>
</template>

<script>
import Hero from "../components/sales/hero.vue";
import Store from "../components/sales/store.vue";
export default {
  components: { Hero, Store },
};
</script>


<style>
</style>