<template>
  <div>
    <hero />
    <Services/>
    <Banner_hero :info="findwork" />
    <advantages />
    <Banner_hero :info="findstaff" />
    <Banner_hero :info="ourprovider" />
  </div>
</template>

<script>
import Banner_hero from "../../components/banner_hero.vue";
import Advantages from "../../components/divisions/outsourcing/advantages.vue";
import Hero from "../../components/divisions/outsourcing/hero.vue";
import Services from '../../components/divisions/outsourcing/services.vue';
export default {
  data() {
    return {
      findwork: {
        title: "¿Buscas trabajo?",
        content:
          "Regístrate en nuestra base de datos y accede a las convocatorias que tenemos.",
        img_path: require("@/assets/images/outsourcing/jpg/buscastrabajo.jpg"),
        button_text: "Regístrate",
        button_to: "/nuevo_personal",
      },
      findstaff: {
        title: "¿Necesitas personal?",
        content:
          "Estamos para ayudarte. Contacta con nosotros y buscaremos el personal que necesitas.",
        img_path: require("@/assets/images/outsourcing/jpg/necesitaspersonal.jpg"),
        button_text: "Contactar",
        button_to: "/busco_personal",
      },
      ourprovider: {
        title: "¿Te interesa ser proveedor?",
        content:
          "Registra a tu empresa para que un ejecutivo se ponga en contacto.",
        button_text: "Registrarse",
        button_to: "/nuestro_proveedor",
      },
    };
  },
  components: {
    Hero,
    Banner_hero,
    Advantages,
    Services,
  },
};
</script>


<style>
</style>