<template>
  <v-row justify="center" class="pb-15 no-gutters">
    <v-col cols="12" class="text-center py-10 px-5">
      <p class="text-h5 text-sm-h4 text-lg-h3 font-weight-bold dit-text-primary">
        VENTAJAS DE <span class="dit-text-accent">DIT OUTSOURCING</span>
      </p>
    </v-col>
    <v-col cols="10" md="10" lg="9" xl="6">
      <v-row>
        <v-col cols="12" sm="6" v-for="item in content" :key="item.title">
          <v-card
            class="rounded-xl"
            :style="item.hover ? style : style_hover"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            :height="
              $vuetify.breakpoint.xs
                ? '140px'
                : $vuetify.breakpoint.sm
                ? '130px'
                : $vuetify.breakpoint.md
                ? '120px'
                : $vuetify.breakpoint.lg
                ? '100px'
                : '100px'
            "
          >
            <v-row
              align="center"
              class="dit-text-accent font-weight-bold text-h5 text-center no-gutters mx-10"
              style="height: 100%"
            >
              <v-spacer></v-spacer>
              <v-col cols="12">{{ item.title }}</v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      style: "border: 3px solid #009EE2 !important;",
      style_hover: "border: 3px solid #003B77 !important;",
      content: [
        {
          title: "Ahorro en costos de proyectos",
          hover: false,
        },
        {
          title: "Personal calificado con experiencia",
          hover: false,
        },
        {
          title: "Flexibilidad en proyectos y tiempo",
          hover: false,
        },
        {
          title: "Aumento de competividad de la empresa",
          hover: false,
        },
        {
          title: "Confidencialidad y confianza",
          hover: false,
        },
        {
          title: "Control y gestión de personal",
          hover: false,
        },
      ],
    };
  },
};
</script>

<style>
</style>