<template>
  <v-footer color="#013156">
    <v-row justify="center"  class="no-gutters">
      <v-col cols="8" sm="6" md="2" lg="2" >
        <v-img :src="require('@/assets/logo.png')" style="width: 100% !important"/>
      </v-col>
      <v-col cols="10">
        <v-row class="white--text mt-5 no-gutters">
          <v-col
            cols="12"
            xs="10"
            sm="6"
            md="4"
            lg="4"
            v-for="item in Servicios"
            :key="item.title"
          >
            <p class="text-h5">{{ item.title }}</p>
            <v-divider color="white" class="mb-3"></v-divider>
            <v-btn
              cols="12"
              text
              v-for="x in item.items"
              :key="x.title"
              color="white"
              class="col text-left v-btn-footer"
              :to="x.to"
            >
              {{ x.title }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            xs="10"
            sm="6"
            md="4"
            lg="4"
            class="hidden-sm-only"
            v-for="item in menu"
            :key="item.title"
          >
            <p class="text-h5">{{ item.title }}</p>
            <v-divider color="white" class="mb-3"></v-divider>
            <v-btn
              cols="12"
              text
              v-for="x in item.items"
              :key="x.title"
              color="white"
              class="col text-left v-btn-footer"
              :to="x.to"
            >
              {{ x.title }}
            </v-btn>
          </v-col>
          <v-col cols="12" xs="10" sm="6" md="4" lg="4">
            <p class="text-h5">Contactos</p>
            <v-divider color="white" class="mb-3"></v-divider>
            <v-btn
              cols="12"
              plain
              color="white"
              v-for="item in contactItems"
              :key="item.title"
              :href="item.href"
              class="col text-left v-btn-footer"
              target="_blank"
            >
              <v-icon left> {{ item.icon }} </v-icon>
              {{ item.title }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="white--text mb-5 mt-3" no-gutters>
          <v-col cols="12">
            <p class="font-weight-medium text-h6">
              ¡Visítanos en nuestras oficinas!
            </p>
          </v-col>
          <v-col cols="12">
            <v-alert
              color="transparent"
              elevation="0"
              icon="mdi-map-marker"
              class="v-alert-icon-white"
            >
              <p class="white--text" style="line-height: 25px">
                La Paz: Calle 22 de Calacoto #8240<br />
                Santa Cruz: Av. San Martín calle Los Jazmines #3 - Barrio Sirari
              </p>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      Servicios: [
        {
          title: "Servicios",
          items: [
            { title: "DIT Carrier", to: "/ditcarrier" },
            { title: "DIT Enterprise", to: "/ditenterprise" },
            { title: "DIT Transporte", to: "/dittransporte" },
            { title: "DIT Outsourcing", to: "/ditoutsourcing" },
            { title: "DIT Mantenimiento", to: "/ditmantenimiento" },
          ],
        },
      ],
      menu: [
        {
          title: "Menú",
          items: [
            { title: "Inicio", to: "/" },
            { title: "Nosotros", to: "/nosotros" },
            { title: "Tienda", to: "/ventas" },
            { title: "Contacto", to: "/contacto" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      contactItems: (state) => state.contactItems,
    }),
  },
};
</script>

<style lang="scss">
</style>