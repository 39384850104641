var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"dit-hero no-gutters",style:(_vm.$vuetify.breakpoint.xs
      ? 'height:450px'
      : _vm.$vuetify.breakpoint.sm
      ? 'height:455px'
      : _vm.$vuetify.breakpoint.md
      ? 'height:475px'
      : _vm.$vuetify.breakpoint.lg
      ? 'height:670px'
      : 'height:890px'),attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align-self":"start"}}),_c('v-col',{staticClass:"text-center dit-letter",attrs:{"cols":"10","sm":"8","md":"8","align-self":"center"}},[_c('p',{staticClass:"text-h4 text-sm-h3 text-md-h2 dit-text-shadow font-weight-bold dit-letter mb-0",staticStyle:{"color":"#009ee2","letter-spacing":"0.01em !important"}},[_vm._v(" Contáctanos ")]),_c('p',{staticClass:"text-h4 text-sm-h3 text-md-h2 dit-text-shadow font-weight-bold white--text dit-letter",staticStyle:{"letter-spacing":"0.01em !important"}},[_vm._v(" para desarrollar tu proyecto ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"10","sm":"8","md":"8"}},[_c('v-btn',{staticClass:"ma-2 dit-btn white--text rounded-pill",attrs:{"large":"","color":"#009EE2","to":"/contacto"}},[_vm._v("Contactar")]),_c('v-btn',{staticClass:"ma-2 dit-btn-outlined rounded-pill",attrs:{"large":"","outlined":"","color":"white"},on:{"click":function($event){return _vm.scrollfade('scrolled')}}},[_vm._v("¿Qué hacemos?")])],1)],1)],1),_c('v-col',{staticClass:"text-center py-5",attrs:{"align-self":"end","cols":"12"}},[_c('a',{on:{"click":function($event){return _vm.scrollfade('scrolled')}}},[_c('img',{attrs:{"src":require('@/assets/images/mouse_down.svg'),"height":"55px"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }