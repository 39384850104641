<template>
  <v-card
    elevation="0"
    style="
      background-color: transparent;
      border: 3px solid #009ee2;
      border-radius: 30px;
    "
    class="mt-10"
  >
    <v-form ref="_form" v-model="valid" lazy-validation>
      <v-row class="pt-10 px-5 no-gutters">
        <v-col cols="12">
          <p class="text-h4 white--text text-center pb-5">
            {{ info.title }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-for="item in formlabels"
          :key="item.model"
          class="px-5"
        >
          <v-text-field
            outlined
            dense
            :label="item.label"
            v-model="form[item.model]"
            required
            :rules="[(v) => !!v || '*Requerido']"
            class="text-input-white dit-input"
            color="white"
            :type="item.type"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          :md="info.divisions.length > 1 ? '6' : '12'"
          class="px-5"
          v-if="divisions_list.length > 1"
        >
          <v-select
            dense
            :items="divisions_list"
            v-model="form['division']"
            label="Servicio de tu interés"
            outlined
            class="text-input-white dit-input"
            :onChange="onDivisionChange()"
            :rules="[(v) => !!v || '*Requerido']"
            required
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          :md="info.divisions.length > 1 ? '6' : '12'"
          class="px-5"
          v-if="is_division_selected && division.areas"
        >
          <v-select
            dense
            :items="division.areas"
            v-model="form['area']"
            label="Servicio de tu interés"
            outlined
            class="text-input-white dit-input"
            :rules="[(v) => !!v || '*Requerido']"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" class="px-5">
          <v-textarea
            label="Comentario"
            class="text-input-white dit-input dit-textarea"
            v-model="form['comment']"
            :rules="[(v) => !!v || '*Requerido']"
            auto-grow
            outlined
            rows="3"
            shaped
          ></v-textarea>
        </v-col>
        <v-col class="text-center">
          <v-snackbar v-model="snackbar" :timeout="3000" color="primary">
            {{ snackbar_text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
          <v-btn
            outlined
            large
            color="#009EE2"
            class="ma-2 mb-5 dit-btn-outlined white--text rounded-pill"
            @click="submit"
          >
            CONTACTAR
          </v-btn>
        </v-col>
      </v-row></v-form
    >
  </v-card>
</template>

<script>
import firebase from "firebase";
export default {
  props: ["info"],
  data() {
    return {
      division: [],
      divisions_list: [],
      is_division_selected: false,
      area: [],
      formlabels: [
        {
          label: "Empresa:",
          model: "company",
          type: "text",
        },
        {
          label: "Dirección:",
          model: "address",
          type: "text",
        },
        {
          label: "Nombres:",
          model: "name",
          type: "text",
        },
        {
          label: "Apellidos:",
          model: "lastname",
          type: "text",
        },
        {
          label: "Correo:",
          model: "email",
          type: "email",
        },
        {
          label: "Teléfono/Celular:",
          model: "phone",
          type: "number",
        },
      ],
      form: {},
      valid: true,
      snackbar: false,
      snackbar_text: "Formulario enviado!.",
    };
  },
  beforeMount() {
    this.processinfo();
  },
  methods: {
    processinfo: function () {
      if (this.info.divisions.length === 1) {
        this.division = this.info.divisions[0];
        this.form["division"] = this.info.divisions.title_lowercase;
        this.is_division_selected = true;
      } else {
        this.info.divisions.forEach((division) => {
          this.divisions_list.push(division.title);
        });
      }
    },
    submit: function () {
      const self = this;
      if (this.$refs._form.validate()) {
        firebase
          .firestore()
          .collection("marketing_contact")
          .add(this.form)
          .then(function () {
            self.$refs._form.reset();
            self.snackbar = true;
          });
        /*.catch(function(error) {
          });*/
      }
    },
    onDivisionChange: function () {
      this.info.divisions.forEach((division) => {
        if (division.title === this.form["division"]) {
          this.division = division;
          this.is_division_selected = true;
        }
      });
    },
  },
};
</script>

<style>
</style>