var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"no-gutters",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10"},[_vm._v(" Nuestra "),_c('span',{staticClass:"dit-text-accent"},[_vm._v("propuesta")]),_vm._v(" de valor ")])]),_c('v-col',{staticClass:"mb-15 hidden-sm-and-up",attrs:{"cols":"12"}},[_c('slider',{ref:"slider",attrs:{"options":_vm.options}},[_vm._l((_vm.proposalList),function(item,index){return _c('slideritem',{key:index,style:(_vm.slider_style)},[_c('v-card',{staticClass:"ma-5",staticStyle:{"border-radius":"50px"},attrs:{"color":"#E5E5E5","dark":"","height":"300px"}},[_c('v-img',{staticClass:"mt-5 ml-5 mr-5",attrs:{"src":item.image}}),_c('v-card-title',{staticClass:"carrousel_title"},[_vm._v(_vm._s(item.title))])],1)],1)}),_c('div',{attrs:{"slot":"loading"},slot:"loading"},[_vm._v("loading...")])],2)],1),_c('v-col',{staticClass:"mb-15 hidden-xs-only",attrs:{"cols":"12","sm":"10","md":"12","lg":"10"}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.proposalList),function(item){return _c('v-col',{key:item.path,attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-card',{staticClass:"proposalitems pa-2",staticStyle:{"border-radius":"50px"},attrs:{"color":"#E5E5E5","height":_vm.$vuetify.breakpoint.xs
              ? '250px'
              : _vm.$vuetify.breakpoint.sm
              ? '210px'
              : _vm.$vuetify.breakpoint.md
              ? '200px'
              : _vm.$vuetify.breakpoint.lg
              ? '230px'
              : '305px'},on:{"mouseover":function($event){item.hover = true},"mouseleave":function($event){item.hover = false}}},[_c('v-img',{attrs:{"src":item.hover ? item.image_hover : item.image,"contain":""}}),_c('v-card-title',{staticClass:"carrousel_title_md",style:(_vm.$vuetify.breakpoint.sm
                ? 'font-size:17px; line-height: 15px;'
                : _vm.$vuetify.breakpoint.md
                ? 'font-size:17px; line-height: 15px;'
                : _vm.$vuetify.breakpoint.lg
                ? 'font-size:21px; line-height: 20px;'
                : 'font-size:27px; line-height: 25px;')},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }