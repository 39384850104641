<template>
  <v-row class="mb-10 no-gutters" justify="center" id="scrolled">
    <v-col cols="10">
      <p
        class="
          text-h5 text-sm-h4 text-md-h4 text-lg-h3
          dit-text-primary
          font-weight-bold
          text-center
          mt-10
          mb-10
        "
      >
        Conoce nuestros <span class="dit-text-accent">5 SERVICIOS</span>
      </p></v-col
    >
    <v-col cols="10" sm="10" md="8">
      <v-row justify="center">
        <v-col
          cols="10"
          sm="4"
          md="4"
          v-for="item in services"
          :key="item.path"
        >
          <v-card
            :style="item.style"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            class="description"
            :class="item.hover && item.hover_class"
            :color="item.hover ? item.color : 'transparent'"
            :to="item.to"
          >
            <v-img :src="item.hover ? item.hover_path : item.path" />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          path: require("@/assets/images/inicio/svg/ditcarrier.svg"),
          hover: false,
          hover_path: require("@/assets/images/inicio/svg/ditcarrier_hover.svg"),
          style: "border: 3px solid #1B4864; border-radius: 50px",
          color: "#1B4864",
          class: "ditcarrier",
          hover_class: "ditoutsourcing_hover",
          to: "/ditcarrier",
        },
        {
          path: require("@/assets/images/inicio/svg/ditenterprise.svg"),
          hover: false,
          hover_path: require("@/assets/images/inicio/svg/ditenterprise_hover.svg"),
          style: "border: 3px solid #306D9A; border-radius: 50px",
          color: "#306D9A",
          class: "ditenterprise",
          hover_class: "ditenterprise_hover",
          to: "/ditenterprise",
        },

        {
          path: require("@/assets/images/inicio/svg/dittransporte.svg"),
          hover: false,
          hover_path: require("@/assets/images/inicio/svg/dittransporte_hover.svg"),
          style: "border: 3px solid #013156; border-radius: 50px",
          color: "#013156",
          class: "dittransporte",
          hover_class: "dittransporte_hover",
          to: "/dittransporte",
        },
        {
          path: require("@/assets/images/inicio/svg/ditoutsourcing.svg"),
          hover: false,
          hover_path: require("@/assets/images/inicio/svg/ditoutsourcing_hover.svg"),
          style: "border: 3px solid #AAAAC1; border-radius: 50px",
          color: "#AAAAC1",
          class: "ditoutsourcing",
          hover_class: "ditoutsourcing_hover",
          to: "/ditoutsourcing",
        },
        {
          path: require("@/assets/images/inicio/svg/ditmantenimiento.svg"),
          hover: false,
          hover_path: require("@/assets/images/inicio/svg/ditmantenimiento_hover.svg"),
          style: "border: 3px solid #635F63; border-radius: 50px",
          color: "#635F63",
          class: "ditmantenimiento",
          hover_class: "ditmantenimiento_hover",
          to: "/ditmantenimiento",
        },
      ],
    };
  },
};
</script>

<style lang=scss>
</style>