var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"no-gutters",staticStyle:{"background-color":"#e5e5e5"},attrs:{"justify":"center","id":"scrolled"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"\n        text-h5 text-sm-h4 text-md-h4 text-lg-h3\n        dit-text-primary\n        font-weight-bold\n        text-center\n        mt-10\n        mb-10\n      "},[_vm._v(" ¿Qué ofrecemos? ")])]),_c('v-col',{staticClass:"mb-15",attrs:{"cols":"10","sm":"10","md":"8"}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.services),function(item){return _c('v-col',{key:item.path,attrs:{"cols":"10","sm":"4","md":"4","xl":"3"}},[_c('v-card',{style:(item.style),attrs:{"color":item.hover ? item.color : 'transparent',"height":_vm.$vuetify.breakpoint.xs
              ? '240px'
              : _vm.$vuetify.breakpoint.sm
              ? '210px'
              : _vm.$vuetify.breakpoint.md
              ? '220px'
              : _vm.$vuetify.breakpoint.lg
              ? '300px'
              : '300px'},on:{"mouseover":function($event){item.hover = true},"mouseleave":function($event){item.hover = false},"click":function($event){return _vm.scrollfade(item.title)}}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-img',{staticClass:"ml-5 mr-5 mt-5",attrs:{"src":item.hover ? item.hover_path : item.path}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"\n                  text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 text-center\n                  font-weight-bold\n                ",style:(item.hover ? 'color:white' : 'color:#1B4864')},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)],1)],1)}),1)],1),_c('v-col',{staticStyle:{"background-color":"#ffffff"},attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-15 mb-15 no-gutters",attrs:{"justify":"center"}},_vm._l((_vm.services),function(item){return _c('v-col',{key:item.path,attrs:{"cols":"8","id":item.title}},[_c('v-card',{style:(item.style)},[_c('v-row',{staticClass:"mt-5 mb-5",attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"10","sm":"8","md":"4","lg":"3","xl":"3"}},[_c('v-img',{staticClass:"ml-10 mr-10",attrs:{"src":item.path}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"\n                  text-sm-h6 text-md-h5 text-lg-h4 text-left\n                  font-weight-bold\n                ",staticStyle:{"color":"#009ee2"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.content)+" ")]),_c('ul',_vm._l((item.list),function(l){return _c('li',{key:l},[_vm._v(_vm._s(l))])}),0)])],1)],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-10 mb-5 dit-btn-carrier white--text rounded-pill",attrs:{"large":"","color":"#009EE2","to":"/contacto"}},[_vm._v(" Contactar ")])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }