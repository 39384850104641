<template>
  <v-row
    class="no-gutters"
    justify="center"
    style="background-color: #e5e5e5"
    id="scrolled"
  >
    <v-col cols="10">
      <p
        class="
          text-h5 text-sm-h4 text-md-h4 text-lg-h3
          dit-text-primary
          font-weight-bold
          text-center
          mt-10
          mb-10
        "
      >
        ¿Qué ofrecemos?
      </p></v-col
    >
    <v-col cols="10" sm="10" md="8" class="mb-15">
      <v-row justify="center">
        <v-col
          cols="10"
          sm="5"
          md="4"
          xl="4"
          v-for="item in services"
          :key="item.path"
        >
          <v-card
            :style="item.style"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            :color="item.hover ? item.color : 'transparent'"
            :height="
              $vuetify.breakpoint.xs
                ? '230px'
                : $vuetify.breakpoint.sm
                ? '250px'
                : $vuetify.breakpoint.md
                ? '220px'
                : $vuetify.breakpoint.lg
                ? '300px'
                : '370px'
            "
            v-on:click="scrollfade(item.title)"
          >
            <v-row align="center" justify="center">
              <v-col cols="8">
                <v-img
                  :src="item.hover ? item.hover_path : item.path"
                  class="ml-5 mr-5 mt-5"
                />
              </v-col>
              <v-col cols="10">
                <p
                  class="
                    text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 text-center
                    font-weight-bold
                  "
                  :style="item.hover ? 'color:white' : 'color:#306D9A'"
                >
                  {{ item.title }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="background-color: #ffffff">
      <v-row justify="center" class="mt-15 mb-15 no-gutters">
        <v-col
          cols="8"
          v-for="item in services"
          :key="item.path"
          :id="item.title"
        >
          <v-card :style="item.style">
            <v-row align="start" justify="center" class="mt-5 mb-5">
              <v-col cols="10" sm="8" md="4" lg="3" xl="3">
                <v-img :src="item.path" class="ml-10 mr-10" />
              </v-col>
              <v-col cols="7">
                <p
                  class="
                    text-sm-h6 text-md-h5 text-lg-h4 text-left
                    font-weight-bold
                  "
                  style="color: #009ee2"
                >
                  {{ item.title }}
                </p>
                <p>
                  {{ item.content }}
                </p>
                <ul>
                  <li v-for="l in item.list" :key="l">{{ l }}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card>
          <v-col cols="12" style="text-align: center">
            <v-btn
              large
              color="#009EE2"
              class="mt-10 mb-5 dit-btn-enterprise white--text rounded-pill"
              to="/contacto"
            >
              Contactar
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Cableado estructurado",
          path: require("@/assets/images/enterprise/svg/cableado.svg"),
          hover: false,
          hover_path: require("@/assets/images/enterprise/svg/cableado_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#306D9A",
          content:
            "Todo negocio requiere una infraestructura sólida y confiable a la hora de trabajar, es por eso que nuestro personal cuenta con equipos y herramientas para realizar todos los trabajos de cableado estructural, podemos detallar los siguientes: ",
          list: [
            "Survey",
            "Dimensionamiento y diseño",
            "Implementación y construcción de redes punto a punto, multipunto FTTH, FTTB  (edificios) y conexiones a abonados. ",
            "Cableado e instalación punto de red",
            "Documentación AsBuilt",
            "Soporte técnico",
          ],
        },
        {
          title: "Fibra óptica",
          path: require("@/assets/images/enterprise/svg/fibraoptica.svg"),
          hover: false,
          hover_path: require("@/assets/images/enterprise/svg/fibraoptica_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#306D9A",
          content:
            "Sabemos bien que cuando una entidad crece, lo principal es estar comunicados e integrados a la red de la empresa, es así que nosotros te ayudamos a mantener la interconexión en tus oficinas metropolitanas y también a dar mantenimiento a los enlaces. Los servicios que destacamos son:",
          list: [
            "Survey y diseño de redes.",
            "Gestión de permisos con entidades públicas: eléctricas, alcaldías, gobernaciones y ministerios.",
            "Implementación y construcción de redes punto a punto.",
            "Certificados de redes.",
            "Mediciones OTDR",
            "Mediciones de Potencia",
            "Documentación As-Built",
            "Asistencia técnica, integración y optimización de redes",
          ],
        },

        {
          title: "Redes, seguridad y transmisión",
          path: require("@/assets/images/enterprise/svg/redes.svg"),
          hover: false,
          hover_path: require("@/assets/images/enterprise/svg/redes_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#306D9A",
          content:
            "Trabaja tranquilo que nosotros nos ocupamos de tus datos, seguridad y comunicación. Hoy en día la información y comunicación son vitales para una empresa, por eso, nuestra área de trabajo destaca por tener personal con experiencia en dar soluciones óptimas ofreciendo los siguientes servicios: ",
          list: [
            "Configuración de VPN",
            "Servidores",
            "Configuración de VoIP ",
            "Centrales telefónicas IP",
            "Sistemas de seguridad y vigilancia",
            "Centros de monitoreo",
            "Seguridad perimetral",
            "Mantenimiento de radio frecuencia",
          ],
        },
        {
          title: "Obras civiles",
          path: require("@/assets/images/enterprise/svg/obrasciviles.svg"),
          hover: false,
          hover_path: require("@/assets/images/enterprise/svg/obrasciviles_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#306D9A",
          content:
            "Puedes tener los mejores equipos, pero sin una infraestructura sólida estás en riesgo, con los cambios repentinos y expansiones empresariales es necesaria un área dedicada a los sistemas informáticos, nosotros te damos el soporte para lograrlo: ",
          list: ["Construcción de DataCenter", "Construcción de Shelter"],
        },
        {
          title: "Proyectos dedicados",
          path: require("@/assets/images/enterprise/svg/proyectos.svg"),
          hover: false,
          hover_path: require("@/assets/images/enterprise/svg/proyectos_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#306D9A",
          content:
            "Si algo sabemos es que no se puede limitar al cliente a englobar sus necesidades cuando necesita una atención personalizada, es por eso que nosotros nos adecuamos a ti y te ofrecemos los siguientes servicios:",
          list: [
            "Servicios de diseño",
            "Servicios de ingeniería",
            "Ejecución de proyectos llave en mano",
            "Certificación de punto de red y fibra óptica",
            "Traslado y reinstalación de equipos",
            "Modernización, licencias y actualizaciones",
            "Asistencia técnica",
            "Servicios especializados a solicitud del cliente",
          ],
        },
        {
          title: "Energía",
          path: require("@/assets/images/enterprise/svg/energia.svg"),
          hover: false,
          hover_path: require("@/assets/images/enterprise/svg/energia_hover.svg"),
          style: "border: 3px solid #009EE2 !important; border-radius: 50px",
          color: "#306D9A",
          content:
            "No sólo se trata de conectar cables, sino de dar seguridad al personal. Nuestro equipo te ayudara a dimensionar y organizar los sistemas de energía de tu empresa, por esa razón, te ofrecemos los siguientes servicios:",
          list: [
            "Adecuación de sistemas eléctricos",
            "Instalación y mantenimiento de generadores",
            "Sistemas de paneles solares",
            "Acometidas de energía",
            "Instalación de paneles y distribución",
            "Inversores y rectificadores",
          ],
        },
      ],
    };
  },
  methods: {
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
.dit-btn-enterprise {
  background-color: #306d9a !important;
  color: #fff !important;
  &:hover {
    background-color: #009ee2 !important;
    color: #fff !important;
  }
}
</style>