<template>
  <div>
    <v-app-bar flat color="#009EE2" height="80">
      <v-img
        :src="require('@/assets/logo.svg')"
        contain
        max-height="50"
        max-width="78"
        v-on:click="goto('/')"
      />
      <div
        class="hidden-sm-and-down ml-10"
        v-for="item in menuItems"
        :key="item.title"
      >
        <v-btn
          v-if="item.subitems.length === 0"
          plain
          v-on:click="subitems_active = false"
          :to="item.path"
          color="white"
        >
          {{ item.title }}
        </v-btn>
        <v-btn
          v-else
          plain
          v-on:click="opensubitems(item.subitems)"
          color="white"
        >
          {{ item.title }}
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        icon
        v-for="item in socialItems"
        :key="item.title"
        :href="item.href"
        target="_blank"
        class="ditgroup-navbar-btn hidden-sm-and-down"
      >
        <v-icon class="ditgroup-navbar-icon">{{ item.icon }}</v-icon>
      </v-btn>
      <v-btn
        icon
        large
        color="white"
        class="hidden-md-and-up"
        @click="drawer = true"
      >
        <v-icon class="ditgroup-navbar-icon">mdi-dots-vertical</v-icon>
      </v-btn>
      <template v-slot:extension v-if="subitems_active">
        <v-tabs centered style="background-color: #e5e5e5">
          <v-tabs-slider color="#009EE2"></v-tabs-slider>
          <v-tab
            v-for="x in subitemsList"
            :key="x.title"
            :to="x.path"
            v-on:click="subitems_active = false"
            style="color: #009ee2; text-transform: none !important"
            >{{ x.title }}</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-img
        :src="require('@/assets/logo_black.svg')"
        v-on:click="goto('/')"
        class="ma-10"
      />
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group
          v-for="item in menuItems"
          color="primary"
          :key="item.title"
        >
          <v-list-group :value="false" v-if="item.title === 'DIVISIONES'">
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item v-for="si in item.subitems" :key="si.title" link>
              <v-list-item-title
                v-text="si.title"
                v-on:click="goto(si.path)"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else v-on:click="goto(item.path)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subitems_active: false,
      subitemsList: [],
      drawer: false,
      menuItems: [
        { title: "INICIO", path: "/", subitems: [] },
        { title: "NOSOTROS", path: "/nosotros", subitems: [] },
        {
          title: "DIVISIONES",
          path: "",
          active: true,
          subitems: [
            {
              title: "DIT Carrier",
              path: "/ditcarrier",
            },
            {
              title: "DIT Enterprise",
              path: "/ditenterprise",
            },
            {
              title: "DIT Transporte",
              path: "/dittransporte",
            },
            {
              title: "DIT Mantenimiento",
              path: "/ditmantenimiento",
            },
            {
              title: "DIT Outsourcing",
              path: "/ditoutsourcing",
            },
          ],
        },
        { title: "TIENDA", path: "/ventas", subitems: [] },
        { title: "CONTACTO", path: "/contacto", subitems: [] },
      ],
      socialItems: [
        { icon: "mdi-whatsapp", href: "https://wa.me/59169701203" },
        { icon: "mdi-facebook", href: "https://www.facebook.com/ditgroupsrl" },
        { icon: "mdi-instagram", href: "https://www.instagram.com/dit.group/" },
      ],
    };
  },
  methods: {
    opensubitems: function (subitems) {
      this.subitemsList = subitems;
      this.subitems_active = true;
    },
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
</style>