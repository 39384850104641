<template>
  <div class="dit-allies pt-10 pb-10">
    <p
      class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10"
    >
      Somos aliados en tus proyectos
    </p>
    <v-btn large color="#009EE2" class="ma-2 dit-btn white--text rounded-pill" to='/contacto'
      >Contactar</v-btn
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">

</style>