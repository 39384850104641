var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"dit-hero-enterprise no-gutters",style:(_vm.$vuetify.breakpoint.xs
      ? 'height:1550px'
      : _vm.$vuetify.breakpoint.sm
      ? 'height:1555px'
      : _vm.$vuetify.breakpoint.md
      ? 'height:775px'
      : _vm.$vuetify.breakpoint.lg
      ? 'height:770px'
      : _vm.$vuetify.breakpoint.xl
      ? 'height:870px'
      : 'height:890px'),attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10","sm":"10","md":"5"}},[_c('v-row',{staticClass:"no-gutters",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-img',{attrs:{"src":_vm.image}})],1),_c('v-col',{staticClass:"white--text text-center mb-9",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"ma-2 dit-btn white--text rounded-pill",attrs:{"large":"","color":"#009EE2","to":"/contacto"}},[_vm._v("Contactar")]),_c('v-btn',{staticClass:"ma-2 dit-btn-outlined white--text rounded-pill",attrs:{"outlined":"","large":"","color":"#009EE2"},on:{"click":function($event){return _vm.scrollfade('scrolled')}}},[_vm._v("¿Qué ofrecemos?")])],1)],1)],1),_c('v-col',{attrs:{"cols":"10","sm":"10","md":"5"}},[_c('Contact',{attrs:{"info":_vm.formitems}})],1),_c('v-col',{staticClass:"text-center py-5",attrs:{"align-self":"end","cols":"12"}},[_c('a',{on:{"click":function($event){return _vm.scrollfade('scrolled')}}},[_c('img',{attrs:{"src":require('@/assets/images/mouse_down.svg'),"height":"55px"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }