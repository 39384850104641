<template>
  <v-row
    class="no-gutters"
    justify="center"
    style="background-color: rgba(170, 170, 193, 0.3)"
    id="scrolled"
  >
    <v-col cols="10">
      <p
        class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 dit-text-primary font-weight-bold text-center mt-10 mb-10"
      >
        ÁREAS DE SERVICIOS <br /><span class="dit-text-accent"
          >DIT OUTSOURCING</span
        >
      </p></v-col
    >
    <v-col cols="10" sm="10" md="8" class="mt-10 mb-15">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          v-for="item in services"
          :key="item.path"
        >
          <v-row
            align="center"
            justify="center"
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            v-on:click="goto(item.to)"
          >
            <v-col cols="10" class="text-center">
              <v-avatar :size="avatar_size">
                <v-img :src="item.path" />
              </v-avatar>
            </v-col>
            <v-col cols="12">
              <p
                class="text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 text-center font-weight-bold"
                :style="item.hover ? 'color: #009EE2' : 'color: #003b77;'"
              >
                {{ item.title }}<br />{{ item.subtitle }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      avatar_size: this.$vuetify.breakpoint.xs
        ? "240px"
        : this.$vuetify.breakpoint.sm
        ? "240px"
        : this.$vuetify.breakpoint.md
        ? "200px"
        : this.$vuetify.breakpoint.lg
        ? "240px"
        : "240px",
      services: [
        {
          title: "Ingeniería en",
          subtitle: "TELECOMUNICACIONES",
          path: require("@/assets/images/outsourcing/jpg/telecomunicaciones.jpg"),
          hover: false,
          to: "/ditoutsourcing/telecomunicaciones",
        },
        {
          title: "Ingeniería",
          subtitle: "MÉDICA",
          path: require("@/assets/images/outsourcing/jpg/medica.jpg"),
          hover: false,
        },
        {
          title: "Ingeniería",
          subtitle: "PETROLERA",
          path: require("@/assets/images/outsourcing/jpg/petrolera.jpg"),
          hover: false,
        },
        {
          title: "Ingeniería",
          subtitle: "INDUSTRIAL",
          path: require("@/assets/images/outsourcing/jpg/industrial.jpg"),
          hover: false,
        },
        {
          title: "Ingeniería en",
          subtitle: "CIVIL",
          path: require("@/assets/images/outsourcing/jpg/civil.jpg"),
          hover: false,
        },
      ],
    };
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
</style>