<template>
  <v-dialog v-model="is_active" max-width="500px" persistent>
    <v-card>
      <v-toolbar color="primary white--text" dark>
        <v-toolbar-title class="font-weight-bold">Registro</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            icon=""
            @click="$store.commit('changeStateRegisterInterface')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-8">
        <v-form ref="_form" v-model="valid" lazy-validatio>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                label="Nombre"
                outlined
                required
                :rules="inputRules"
                v-model="new_user.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email"
                outlined
                required
                :rules="emailRules"
                v-model="new_user.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Telefono"
                outlined
                required
                :rules="inputRules"
                v-model="new_user.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Ciudad"
                outlined
                required
                :rules="inputRules"
                v-model="new_user.city"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Compañia"
                outlined
                required
                :rules="inputRules"
                v-model="new_user.company"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="N.I.T."
                outlined
                required
                :rules="inputRules"
                v-model="new_user.nit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn color="blue darken-1" outlined block @click="create_user"
                >REGISTRAR</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
export default {
  props: ["is_active"],
  data() {
    return {
      new_user: {},
      valid: true,
      emailRules: [
        (v) => !!v || "Campo Requerido",
        (v) => /.+@.+/.test(v) || "Introduzca un email valido",
      ],
      inputRules: [
        (v) => !!v || "Campo Requerido",
        (v) =>
          (v && v.length <= 50 && v.length) ||
          "Name must be less than 10 characters",
      ],
    };
  },
  methods: {
    create_user: function () {
      const self = this;
      if (this.$refs._form.validate()) {
        firebase
          .firestore()
          .collection("clients")
          .where("email", "==", this.new_user.email)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              firebase
                .firestore()
                .collection("clients")
                .add(self.new_user)
                .then((doc) => {
                  self.new_user.id = doc.id;
                  self.$store.commit("login", self.new_user);
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            } else {
              self.new_user = {};
              this.$refs.form.reset();
              self.snackbar.message = "Email ya registrado";
              self.snackbar.color = "error";
              self.snackbar.elevation = "24";
              self.snackbar.status = true;
              self.snackbar.timeout = "2000";
            }
          });
      }
    },
  },
};
</script>

<style>
</style>