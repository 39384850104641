<template>
  <v-form ref="_form" v-model="valid" lazy-validation>
    <v-row justify="center" class="no-gutters">
      <v-col cols="8" class="text-center py-10">
        <p class="text-h4 dit-text-primary font-weight-bold pt-10">
          REGÍSTRATE EN
          <span class="dit-text-accent">NUESTRA BASE DE DATOS</span>
        </p>
      </v-col>
      <v-col cols="10" md="10" lg="10">
        <v-divider style="background-color: #009ee2" />
        <v-row class="pt-10" justify="center">
          <v-col cols="12" class="font-weight-bold"> DATOS PERSONALES </v-col>
          <v-col cols="12" sm="6" md="3" class="text-center">
            <v-card
              :style="
                photo_tempurl === null
                  ? 'border: 1px solid #635f63; box-sizing: border-box'
                  : ''
              "
              elevation="0"
              class="rounded-xl"
              @click="choosesPhoto()"
            >
              <v-img :src="photo_tempurl" aspect-ratio="1" />
            </v-card>
            <v-file-input
              id="PhotoUpload"
              type="file"
              class="d-none"
              @change="ChangePhoto($event)"
              accept="image/*"
            />
          </v-col>
          <v-col cols="12" sm="12" md="9">
            <v-row class="">
              <v-col cols="12" md="6" class="mb-0">
                <v-text-field
                  outlined
                  label="Nombre"
                  v-model="new_personal.name"
                  required
                  dense
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Apellido"
                  v-model="new_personal.lastname"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Email"
                  v-model="new_personal.email"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Ciudad de residencia"
                  v-model="new_personal.city"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Nacionalidad"
                  v-model="new_personal.country"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="CI (Carnet de identidad) :"
                  v-model="new_personal.ci"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Teléfono"
                  v-model="new_personal.phone"
                  required
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Fecha de Nacimiento"
                  v-model="new_personal.date"
                  required
                  type="date"
                  :rules="[(v) => !!v || 'Campo Requerido']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider style="background-color: #009ee2" class="my-10" />
        <v-row class="no-gutters">
          <v-col cols="12" class="font-weight-bold"> IDIOMAS </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="Idioma"
              v-model="new_language"
              v-on:keyup.enter="addLanguage(new_language)"
              required
              d-inline-block
            ></v-text-field>
            <v-chip
              class="ma-2"
              color="#009ee2"
              outlined
              pill
              close
              @click:close="removeLanguage(language)"
              v-for="language in languages"
              :key="language.language"
            >
              {{ language.language }}
            </v-chip>
          </v-col>
        </v-row>
        <v-divider style="background-color: #009ee2" class="my-10" />
        <v-row class="no-gutters">
          <v-col cols="12" class="font-weight-bold"> AREA </v-col>
          <v-col cols="12">
            <v-select
              :items="areas"
              label="Selecciona el area de tu interés"
              outlined
              v-model="new_personal.area"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider style="background-color: #009ee2" class="my-10" />
        <v-row class="no-gutters">
          <v-col cols="12" class="font-weight-bold">
            TRABAJO Y EDUCACIÓN
          </v-col>
          <v-col>
            <v-file-input
              label="Subir Currículum"
              outlined
              dense
              @change="onChangeCurriculum($event)"
              accept="application/pdf,application/msword"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="pt-10 no-gutters">
          <v-col cols="12" class="font-weight-bold"> REDES SOCIALES </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Linkedin"
              prepend-icon="mdi-linkedin "
              v-model="new_personal.linkedin"
            ></v-text-field>
            <v-text-field
              outlined
              label="Facebook"
              prepend-icon="mdi-facebook "
              v-model="new_personal.facebook"
            ></v-text-field>
            <v-text-field
              outlined
              label="Instagram"
              prepend-icon="mdi-instagram "
              v-model="new_personal.instagram"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center pb-15">
        <v-btn large class="dit-btn rounded-pill" @click="submit"
          >ENVIAR POSTULACIÓN
        </v-btn>
        <v-snackbar v-model="snackbar" :timeout="3000" color="primary">
          {{ snackbar_text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      photo: null,
      photo_tempurl: require("@/assets/images/user_profile.jpg"),
      new_personal: {},
      curriculum_file: null,
      date: new Date(),
      new_language: "",
      languages: [],
      snackbar: false,
      snackbar_text: "Formulario enviado!.",
      valid: true,
      areas: [
        "Ingeniería en Telecomunicaciones",
        "Ingeniería Médica",
        "Ingeniería Petrolera",
        "Ingeniería Industrial",
        "Ingeniería Civil",
      ],
    };
  },
  methods: {
    addLanguage: function (language) {
      let n_l = { language: language };
      this.languages.push(n_l);
      this.new_language = "";
    },
    removeLanguage: function (language) {
      this.languages.splice(language);
    },
    choosesPhoto() {
      document.getElementById("PhotoUpload").click();
    },
    ChangePhoto(e) {
      this.photo = e;
      this.photo_tempurl = window.URL.createObjectURL(this.photo);
    },
    onChangeCurriculum(e) {
      this.curriculum_file = e;
    },
    submit: function () {
      const self = this;
      const curriculum = self.curriculum_file;
      const personal_photo = self.photo;
      console.log(curriculum,personal_photo);
      if (this.$refs._form.validate()) {
        self.new_personal.languages = self.languages;
        firebase
          .firestore()
          .collection("outsourcing_looking_job")
          .add(self.new_personal)
          .then((doc) => {
            let photo_route = "outsourcing/looking_job/" + doc.id + ".jpg";
            let curriculum_route = "outsourcing/looking_job/" + doc.id + ".pdf";
            self.uploadfile(doc.id, personal_photo, photo_route, false);
            self.uploadfile(doc.id, curriculum, curriculum_route, true);
            self.photo_tempurl = require("@/assets/images/user_profile.jpg");
            self.languages=[];
            self.$refs._form.reset();
            self.snackbar = true;
          });

        this.snackbar = true;
        this.$refs._form.reset();
      }
    },
    uploadfile: function (id, file, route, iscurriculumfile) {
      let storageRef = firebase.storage().ref();
      let uploadTask;
      let metadata = {
        contentType: "application/pdf",
      };
      if (iscurriculumfile) {
        metadata = {};
        uploadTask = storageRef.child(route).put(file, metadata);
      } else {
        let metadata = {
          contentType: "image/jpeg",
        };
        uploadTask = storageRef.child(route).put(file, metadata);
      }

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //var progress =
          //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            if (iscurriculumfile) {
              firebase
                .firestore()
                .collection("outsourcing_looking_job")
                .doc(id)
                .update({
                  curriculum_path: downloadURL,
                });
            } else {
              firebase
                .firestore()
                .collection("outsourcing_looking_job")
                .doc(id)
                .update({
                  photo_path: downloadURL,
                });
            }
          });
        }
      );
    },
  },
};
</script>

<style>
</style>